.forum-page-container {
  max-width: 75rem;
  width: 100%;
  margin: 0 auto 2.25rem auto;
}

.forum-main-content-container {
  display: flex;
  margin-top: 1.75rem;
}

// left hand side of forum page
.forum-sidebar-container {
  position: sticky;
  position: -webkit-sticky;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  min-width: 20rem;
  max-width: 24rem;
  width: 35%;
  color: $black;
  background-color: $white;
  border-radius: 0.63rem;
  box-shadow: $secondary-shadow;
  padding: 1.5rem;
  top: 6.25rem;
  margin-right: 1.5rem;
  z-index: 79;
}

.forum-searchbar-container {
  position: relative;
  border-radius: 3px;
  overflow: hidden;

  &:focus-within {
    box-shadow: $input-focus-shadow;
  }
}

.forum-searchbar {
  color: $black;
  font-weight: $bold;
  background-color: $white;
  height: 3.2rem;
  width: 100%;
  border: 1px solid $color-accent;
  outline: none;
  border-radius: 3px;
  box-shadow: $tertiary-shadow;
  padding-left: 1rem;

  &::placeholder {
    color: $color-text-secondary;
    opacity: 1;
  }

  &:placeholder-shown {
    background-color: $color-neutral;
    border-color: $color-neutral;
  }

  &:focus {
    border-color: $color-primary;
  }
}

.forum-searchbar-icon-container {
  display: inline;
  position: absolute;
  font-size: 1.25rem;
  color: $white;
  background-color: $color-primary;
  width: 2.75rem;
  height: 100%;
  border-radius: 0 3px 3px 0;
  right: 0;
  cursor: pointer;
  transition: $main-transition;
  -webkit-transition: $main-transition;

  &:hover {
    background-color: $color-primary-highlight;
    transition: $main-transition;
    -webkit-transition: $main-transition;
  }
}

.forum-searchbar-x-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: $black;
  background-color: $white;
  width: 2.3rem;
  height: calc(100% - 2px);
  border-radius: 0 3px 3px 0;
  top: 1px;
  right: 1px;
  cursor: pointer;
}

.forum-searchbar-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.forum-searchbar-icon-x {
  transform: scale(1.2, 1);
}

.forum-sidebar-tags-container {
  position: relative;
  border-top: 1px solid $color-accent;
}

.forum-sidebar-tags-header {
  padding: 1.5rem 0;
}

.forum-sidebar-tags-inner-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.forum-filter-tag-icon {
  width: 1.25rem;
  height: 1.25rem;
  background-repeat: no-repeat;
  background-image: url("https://carecopilot-static-assets.s3.amazonaws.com/tag.png");
  background-size: contain;
  margin-right: 0.63rem;
}

.forum-interactions-modal-container {
  display: none;
}

.forum-interactions-modal-title {
  display: flex;
  gap: 0 0.3rem;
  color: $color-text-primary;
  border-bottom: 1px solid $color-accent;
  padding: 1.5rem;
}

.forum-interactions-modal-options {
  padding: 0.75rem 0;
}

.forum-interactions-modal-option {
  display: flex;
  align-items: center;
  gap: 1.25rem;
  color: $color-text-primary;
  font-size: 1.12rem;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;

  &:hover {
    color: $color-primary;
  }
}

.forum-interactions-modal-icon {
  width: 1.35rem;
}

.forum-guidelines-container {
  position: relative;
  border-top: 1px solid $color-accent;
  padding-top: 1.5rem;
}

.forum-guidelines-container-mobile {
  display: none;
  height: 2.5rem;
  background-color: $color-accent;
  border: 1px solid $color-accent;
  border-radius: 3px;
}

.forum-guidelines-link {
  display: block;
  color: $black;
  cursor: pointer;
  transition: $main-transition;
  -webkit-transition: $main-transition;
  
  &:visited {
    color: $black;
  }

  &:hover {
    color: $color-primary;
    transition: $main-transition;
    -webkit-transition: $main-transition;
  }
}
// end of left hand side of forum page

// right hand side of forum page
.forum-create-post-container {
  position: sticky;
  position: -webkit-sticky;
  display: flex;
  align-items: center;
  align-self: flex-start;
  background-color: $white;
  width: 100%;
  min-height: 5.56rem;
  border-radius: 5px;
  box-shadow: $secondary-shadow;
  padding: 1.25rem;
  margin-bottom: 1.5rem;
  top: 6.25rem;
  z-index: 78;
}

.forum-create-post-overlay {
  position: fixed;
  width: 100%;
  height: 8.75rem;
  background-color: $white;
  background: linear-gradient(180deg, 
    rgb(251, 253, 255) 82%,
    rgba(251, 253, 255, 0.76) 89%,
    rgba(251, 253, 255, 0.52) 93.5%,
    rgba(251, 253, 255, 0) 100%);
  top: 4.5rem;
  left: 0;
  z-index: 77;
}

.forum-posting-suspended-container {
  color: $black;
  margin-left: 1rem;
}

.forum-posting-suspended-link {
  color: $color-primary;
  margin-left: 0.25rem;

  &:hover {
    color: $color-primary-highlight;
  }
}

.forum-post-modal-inner-container {
  padding: 1.5rem;
  box-shadow: $secondary-shadow;
}

.forum-post-modal-user-info-container {
  display: flex;
  align-items: center;
  padding-right: 1.75rem;
}

.forum-post-modal-avatar {
  min-width: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 0.5rem;
}

.forum-post-modal-handle {
  color: $black;
}

.forum-post-modal-label {
  color: $black;
}

.forum-post-modal-title {
  margin-top: 2rem;
}

.forum-post-modal-input {
  color: $color-text-primary;
  background-color: $white;
  border: 1px solid $color-accent;
  outline: none;
  border-radius: 3px;
  height: 3.1rem;
  resize: none;
  width: 100%;
  padding: 0 1rem;
  padding: 0.65rem 1rem;
  margin-top: 0.5rem;

  &:focus {
    border-color: $color-primary;
    box-shadow: $input-focus-shadow;
  }

  &::placeholder {
    color: $color-text-secondary;
    opacity: 1;
  }
}

.forum-post-modal-textarea {
  min-height: 6.5rem;
}

.forum-tag-icon {
  font-size: 0.85rem;
  margin-right: 0.5rem;
}

// ensure consistent tag width as the plus icon takes up more space than the x
.forum-tag-icon-plus {
  margin-right: 0.4rem;
  margin-left: -0.11rem;
}

.forum-no-posts-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: $black;
  min-height: 15rem;
}

.forum-no-posts {
  color: $black;
  margin: auto;
}

.forum-post-list-container {
  position: relative;
  width: 77%;
  max-width: 49.5rem;
  display: flex;
  flex-direction: column;
}

.forum-search-results-header {
  color: $black;
  margin: 0.25rem 0 1rem 0;
}

.forum-navigation-bar {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: $color-neutral;
  width: 100%;
  height: 3.6rem;
  border-radius: 0.63rem;
  box-shadow: $tertiary-shadow;
  padding: 0.5rem;
  margin-bottom: 1.5rem;
}

.forum-post-input {
  display: block;
  background-color: $white;
  border: 1px solid $color-accent;
  outline: none;
  border-radius: 3px;
  height: 2.9rem;
  resize: none;
  overflow: hidden;
  width: 100%;
  padding: 0.55rem 0.8rem;
  margin-left: auto;

  &:focus {
    border-color: $color-primary;
    box-shadow: $input-focus-shadow;
  }

  &::placeholder {
    color: $color-text-secondary;
    opacity: 1;
  }
}

.forum-post-create-input {
  background-color: $color-neutral;
  box-shadow: $tertiary-shadow;
  margin-left: 0.25rem;
  
  &::placeholder {
    color: $color-text-secondary;
    opacity: 1;
  }
}

.forum-char-limit-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.25rem;
}

.forum-char-limit {
  color: $color-text-secondary;
  text-align: right;
  margin-left: auto;
}

.forum-image-upload-icon {
  font-size: 1.5rem;
  margin-left: 0.5rem;
}

.forum-image-upload-text {
  display: flex;
  align-items: center;
  color: $black;
  cursor: pointer;
  transition: $main-transition;
  -webkit-transition: $main-transition;
  margin-right: auto;
  margin-top: 2rem;

  &:hover {
    color: $color-primary;
    transition: $main-transition;
    -webkit-transition: $main-transition;
  }
}

.forum-comment-input {
  margin: 1rem 0 0.5rem 0;
}

.forum-comment-image-upload-text {
  color: $color-text-primary;
  height: fit-content;
  margin: -2.1rem 0 0 auto;
}

.forum-image-upload-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.forum-image-uploading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.forum-uploaded-img {
  max-width: 100%;
  height: 100%;
  max-height: 20rem;
  object-fit: contain;
  cursor: default;
  margin: 1rem auto 0.5rem auto;
}

.forum-image-uploading-spinner-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.12rem;
  height: 2.25rem;

  .spinner-border {
    height: 1.3rem;
    width: 1.3rem;
    border-width: 0.12rem;
  }
}

.forum-image-uploaded-edit-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.5rem;
  font-size: 1.12rem;
  color: $black;
  min-width: 16rem;
}

.forum-image-uploaded-icon-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  i {
    margin: 0;
  }
}

.forum-image-uploaded-icon {
  font-size: 1.3rem;
}

.forum-image-icon {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  box-shadow: $icon-shadow;
  cursor: pointer;

  &:hover {
    background-color: $color-secondary-5;
  }
}

.multi-select-btn-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 0.5rem auto 0 0;
}

.default-modal-btn-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  margin-top: 2rem;
}

.forum-post-fade-out {
  position: absolute;
  background: linear-gradient(0.03deg, $color-secondary-6 21.78%, rgba(251, 253, 255, 0) 99.97%);
  width: 100%;
  // add 0.5rem to cover bottom box shadow
  height: calc(100% + 0.5rem);
  border-radius: 5px;
  top: 0;
  left: 0;
  z-index: 76;
}

.forum-post-container {
  position: relative;
  border-radius: 5px;
  box-shadow: $secondary-shadow;
  padding: 1.25rem;
  margin-bottom: 0.5rem;
}

.forum-creator-bar {
  display: flex;
  align-items: center;
  width: 100%;
}

.forum-post-expert-badge {
  background-color: $color-tertiary-highlight;
  font-size: $small-text;
  font-weight: $medium;
  text-align: center;
  margin: 0.4rem;
  padding: 0.1rem 0.5rem;
  gap: 1px;
  width: 66px;
  border-radius: 3px;
}

.forum-post-avatar {
  // setting width/height without min/max results in an uneven image
  min-width: 2.5rem;
  min-height: 2.5rem;
  max-width: 2.5rem;
  max-height: 2.5rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
}

.forum-creator-bar-info-container {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.3rem;
  color: $color-text-primary;
  width: 100%;
  margin-top: -0.2rem;
}

.forum-post-when-posted {
  display: block;
}

.forum-post-tags-container {
  display: flex;
  flex-wrap: wrap;
  color: $color-text-secondary;
  width: 100%;
}

.forum-post-tag-container {
  display: flex;
  white-space: nowrap;
}

.forum-post-tag {
  display: block;
  cursor: pointer;
  margin-left: 0.25rem;
  transition: $main-transition;
  -webkit-transition: $main-transition;

  &:hover {
    color: $color-primary;
    transition: $main-transition;
    -webkit-transition: $main-transition;
  }
}

.single-post-tag {
  display: block;
  margin-left: 0.25rem;
}

.forum-post-tag-separator {
  font-size: 1rem;
}

.forum-post-no-tag {
  margin-left: 0.25rem;
}

.forum-post-title {
  color: $black;
  word-break: break-word;
  margin-top: 0.5rem;
}

.forum-post-body {
  color: $black;
  white-space: pre-wrap;
  word-break: break-word;
  margin: 1rem 0;
}

.forum-url {
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  color: $color-primary;

  &:link,
  &:visited {
    color: $color-primary;
  }

  &:hover {
    color: $color-primary-highlight;
  }
}

.forum-post-img {
  display: block;
  max-width: 100%;
  border-radius: 7px;
  margin: 0 auto;
  object-fit: cover;
  max-height: 27rem;
  margin-bottom: 0.5rem;
}

.forum-video-loading-screen {
  width: 100%;
  height: 18rem;
  background-color: #696A6A;
}

.forum-post-video {
  max-height: 27rem;
}

.forum-post-reaction-bar {
  display: flex;
  align-items: center;
  gap: 0.75rem 1rem;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
  color: $color-text-primary;
  margin-top: 1rem;
  padding-left: 0.25rem;
}

.forum-post-interactions-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem 0.62rem;
  margin-left: auto;
}

.forum-comment-reaction-bar {
  display: flex;
  align-items: center;
  width: 100%;
  color: $color-text-primary;
}

.forum-post-likes-comments-container {
  display: flex;
  gap: 0.75rem;
}

.forum-post-likes-container,
.forum-post-comments-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: 2.2rem;
  transition: $main-transition;
  -webkit-transition: $main-transition;

  &:hover {
    color: $color-primary;
    transition: $main-transition;
    -webkit-transition: $main-transition;
  }
}

.forum-post-likes-container-liked {
  display: flex;
  cursor: pointer;
  color: $color-primary;
  min-width: 2.2rem;
}

.forum-post-comments-container {
  min-width: unset;
  &:hover {
    .btn-comments {
      color: $color-primary;
    }
  }
}

.forum-post-likes-icon,
.forum-post-comments-icon {
  display: flex;
  align-items: center;
  min-width: fit-content;
  font-size: 1.2rem;
  min-height: 1.5rem;
}

.forum-post-comments-icon {
  margin-right: 0.3rem;
}

.forum-post-likes {
  font-size: 1rem;
  margin: 0 0 0 0.3rem;
  min-width: 0.5rem;
}

.forum-post-report-icon {
  font-size: 1rem;
}

.forum-post-ellipsis {
  position: absolute;
  display: none;
  color: $color-text-primary;
  padding: 0.1rem 0.5rem;
  top: 0;
  right: -0.25rem;
  cursor: pointer;
}

.forum-btn-message-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.forum-message-container {
  position: absolute;
  font-size: 1.12rem;
  color: $color-text-primary;
  background-color: $white;
  box-shadow: $secondary-shadow;
  border-radius: 0.63rem;
  text-align: center;
  opacity: 0;
  padding: 0.65rem 0.9rem;
  margin-top: 2rem;
  white-space: nowrap;
  overflow: hidden;
  z-index: -1;
}

.forum-message-tick {
  color: $color-secondary-2;
  background-color: $color-primary;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.forum-message-text-container {
  display: inline;
}

.forum-message-link {
  font-weight: $bold;
  text-decoration: underline;
  margin-left: 0.25rem;
  cursor: pointer;

  &:hover {
    color: $color-primary;
  }
}

.forum-comment-container {
  position: relative;
  display: flex;
  margin-top: 1rem;
}

.forum-comment-avatar {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  object-fit: cover;
  margin: 1rem 0.5rem 0 0;
}

.forum-comment-content-container {
  background-color: $color-neutral;
  width: 100%;
  box-shadow: 0 1px 1px 0 rgba(118, 135, 154, 0.2), 0 3px 8px 0 rgba(118, 135, 154, 0.08);
  border-radius: 0.63rem;
  padding: 1rem;
}

.forum-post-show-more-comments {
  color: $color-primary;
  width: fit-content;
  cursor: pointer;
  margin: 1rem 0 0 2.5rem;
  transition: $main-transition;
  -webkit-transition: $main-transition;

  &:hover {
    color: $color-primary-highlight;
    transition: $main-transition;
    -webkit-transition: $main-transition;
  }
}

.forum-comment-likes-container {
  margin-right: auto;
}

// this cannot go into errors.scss or it will be overridden by other forum styles
.forum-input-error {
  border: 1px solid $color-danger-secondary;
  box-shadow: $input-focus-shadow;

  &:focus {
    border: 1px solid $color-danger-secondary;
    box-shadow: $input-focus-shadow;
  }
}

.forum-comment-create-btn-container {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
}

.forum-comment-btn-container {
  display: flex;
  gap: 0.5rem;
}

.forum-loading-screen-container {
  background-color: $white;
  height: 14rem;
  border: 1px solid $color-secondary-5;
  border-radius: 5px;
  box-shadow: $main-shadow;
  padding: 1.25rem 1.12rem;
  margin-bottom: 1.25rem;
}

.forum-loading-screen-avatar-container {
  display: flex;
}

.forum-loading-screen-avatar {
  background-color: $white;
  width: 3.1rem;
  height: 3.1rem;
  border-radius: 50%;
  margin-right: 1.12rem;
}

.forum-loading-screen-line {
  background-color: $white;
  height: 0.63rem;
  border-radius: 0.5rem;
}

.loading-line-top {
  display: block;
  width: 27.5rem;
  max-width: calc(100% - 5rem);
  margin-top: 1.25rem;
}

.loading-line-mid {
  max-width: 44rem;
  margin-top: 2.25rem;
}

.loading-line-bottom {
  max-width: 35rem;
  margin-top: 1.12rem;
}
// end of right hand side of forum page

// forum single post page
.forum-single-post-container {
  max-width: 50rem;
  width: 100%;
  margin: 4rem auto 8rem auto;
}

.forum-all-posts-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.forum-error-page-container {
  text-align: center;
  margin: 10rem auto;
}

.forum-error-page-title {
  color: $color-primary;
  margin-bottom: 1.5rem;
}

.forum-error-page-text {
  color: $color-text-primary;
  padding: 0 4.5rem;
}

.forum-sign-up-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  color: $black;
  padding: 0.5rem;
  margin-bottom: 4.25rem;
}

.forum-login-container {
  display: flex;
  gap: 0.3rem;
}
