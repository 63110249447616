.pricing-page-container {
  max-width: 100rem;
  padding: 7rem 6.25rem 9rem 6.25rem;
  margin: 0 auto;
}

.pricing-title {
  font-family: $heading-font;
}

.pricing-subtitle {
  margin-top: 1rem;

  a {
    color: $black;
    text-decoration: underline;
  }
}

.pricing-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  max-width: 90rem;
  margin: 5rem auto 0 auto;
}

.pricing-card-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: $color-secondary-5;
  flex: 1;
  min-width: 22rem;
  border-radius: 0.63rem;
  padding: 3rem;

  &:nth-of-type(3) {
    // Use total gap in calclation for max-width
    max-width: calc(50% - 1rem);
  }
}

.pricing-card-tag {
  position: absolute;
  font-weight: $medium;
  background-color: $color-secondary-3;
  width: max-content;
  border-radius: 0.63rem;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0.75rem 2.5rem;
}

.pricing-card-title {
  font-size: 1.75rem;
  font-weight: $bold;
  text-align: center;
}

.pricing-card-price {
  text-align: center;
}

.pricing-btn-container {
  margin-top: auto;
}
