.onboarding-page-container {
  position: absolute;
  display: flex;
  justify-content: center;
  background-color: $color-secondary-5;
  width: 100%;
  min-height: 100%;
  z-index: 101;
  overflow: hidden;
  top: 0;
  left: 0;
  padding: 5rem 0;
}

.onboarding-spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.onboarding-container {
  background-color: $white;
  width: 33.25rem;
  height: fit-content;
  box-shadow: $main-shadow;
  border-radius: 5px;
  padding: 2.5rem;
  margin: auto 0;
}

.onboarding-title {
  color: $color-primary;
  text-align: center;
  width: calc(100% - 2.5rem);
  margin: 0 auto;
}

.onboarding-subtitle {
  color: $black;
  text-align: center;
  margin-top: 1.5rem;
}

.onboarding-label {
  position: relative;
  color: $black;
  width: 100%;
  margin-top: 2rem;
  
  &:focus-within {
    .onboarding-input-tick {
      position: absolute;
      display: inline;
      background-color: $color-primary;
      color: $color-secondary-5;
      border-radius: 50%;
      top: 2.7rem;
      right: 0.75rem;
    }
  }
}

.onboarding-label-required {
  color: $color-text-secondary;
}

.onboarding-location-label {
  margin: 2.5rem 0 0.25rem 0;
}

.onboarding-input {
  display: block;
  background-color: $white;
  width: 100%;
  height: 3.12rem;
  outline: none;
  border: 1px solid $color-accent;
  border-radius: 3px;
  padding: 0.75rem 0.88rem;
  margin-top: 0.25rem;
  
  &:focus-within {
    border-color: $color-primary;
    box-shadow: $input-focus-shadow;
  }

  &.onboarding-input-error {
    border-color: $color-danger-primary;
    box-shadow: 0px 0px 0px 2px #FD79A133;
  }
}

.onboarding-tooltip {
  font-size: 1.12rem;
  color: $black;
  cursor: pointer;
  margin-left: 0.38rem;
  pointer-events: all;
}

.onboarding-tooltip-container {
  font-size: 5rem;

  & .tooltip-inner {
    font-family: $main-font;
    font-weight: $light;
    font-size: $small-text;
    line-height: 1.2;
    color: $white;
    background-color: $black;
    min-width: 18rem;
    border-radius: 2px;
    text-align: left;
  }

  & .arrow::after {
    border-right-color: $black !important;
    border-left-color: $black !important;
  }
}

.onboarding-input-tick {
  display: none;
}

.onboarding-field-success {
  display: inline-block;
  color: $color-primary;
  margin-top: 0.25rem;
}

.onboarding-field-error {
  display: inline-block;
  font-size: 1rem;
  margin-top: 0.25rem;
}

.onboarding-field-error-required {
  margin-top: 0.25rem;
}

.onboarding-blank-handle {
  display: inline-block;
  height: 1.3rem;
}

.onboarding-field-interaction {
  color: $black;
  width: fit-content;
  // set height so that other elements don't move when the semibold hover is applied
  min-height: 1.45rem;
  margin: 0.25rem 0;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;

  &:hover {
    font-weight: $semibold;
    letter-spacing: 0.06px;
  }
}

.onboarding-page-dots-container {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  font-size: 0.5rem;
  margin-top: 1rem;
}

.completed-page-dot {
  color: $color-tertiary;
}

.incomplete-page-dot {
  color: $color-accent;
}

.onboarding-finish-btn-container {
  display: flex;
  gap: 1rem;
}

// reusing the radio inputs from AccountUpdateRadio
.onboarding-radio-container {
  margin-top: 2.5rem;

  .account-radio-container {
    margin: 1rem 0 0 0;
  }

  .account-radio-option {
    color: $black;
    margin-bottom: 0.5rem;
  }

  .account-radio-text-input {
    color: $black;
  }
}
