// Abstracts
@import "./abstracts/variables";

// Vendors
@import "react-widgets/styles.css";
@import "pure-react-carousel/dist/react-carousel.es.css";

// Base
@import "./base/animations";
@import "./base/base";
@import "./base/reset";
@import "./base/typography";

// Layout
@import "./layout/container";
@import "./layout/footer";
@import "./layout/header";
@import "./layout/navbar";

// Components
@import "./components/buttons";
@import "./components/cards";
@import "./components/carousel";
@import "./components/dropdowns";
@import "./components/faq";
@import "./components/modal";
@import "./components/popups";

// Pages
@import "./pages/account";
@import "./pages/blog";
@import "./pages/carecopilots";
@import "./pages/contact";
@import "./pages/error-pages";
@import "./pages/forum";
@import "./pages/community-guidelines";
@import "./pages/home";
@import "./pages/onboarding";
@import "./pages/pricing";
@import "./pages/resources";
@import "./pages/services";
@import "./pages/unsubscribe";

// Error styles are overwritten when grouped with components
@import "./components/errors";

// Media queries
@import "./base/media";
