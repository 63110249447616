
.label {
  font-weight: bold;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20rem;
}
