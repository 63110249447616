.services-page-container {
  background-color: $color-secondary-5;
  padding: 4.5rem 1.5rem 9rem 1.5rem;
}

.services-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 56.25rem;
  margin: 0 auto;
}

.services-title {
  max-width: 45rem;
  text-align: center;
}

.services-subtitle {
  text-align: center;
  white-space: pre-wrap;
  margin-top: 1.5rem;
}

.services-content-container {
  max-width: 100%;
  width: 100%;
  box-shadow: $secondary-shadow;
  margin-top: 4rem;
}

.services-navigation-container {
  display: flex;
}

.services-navigation-option {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 0.8rem 0;
  background-color: $color-neutral;
  width: 25%;
  // flex: 1;
  height: 11.75rem;
  // height: 9.8rem;
  padding: 1.7rem 0;
  cursor: pointer;

  &:not(.services-navigation-option-selected) {
    &:hover {
      .services-navigation-icon-container {
        box-shadow: 0px 0px 10px 2px rgba(69, 69, 69, 0.2);
        transition: box-shadow 200ms ease;
        -webkit-transition: box-shadow 200ms ease;
      }
    }
  }

  &:last-of-type {
    cursor: default;
  }
}

.services-navigation-option-selected {
  background-color: $white;
  border-top: 3px solid $black;
}

.services-navigation-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 3.4rem;
  width: 3.4rem;
  height: 3.4rem;
  background-color: $color-secondary-2;
  border: 3px solid $black;
  border-radius: 50%;
  transition: $main-transition;
  -webkit-transition: $main-transition;
}

.services-navigation-option-selected {
  .services-navigation-icon-container {
    background-color: $color-tertiary;

    &:hover {
      box-shadow: none;
    }
  }
}

.services-navigation-icon {
  width: 2rem;
  height: 2rem;
}

.services-navigation-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 66%;
  white-space: pre-wrap;
  text-align: center;
}

// In-home care section
.in-home-care-container {
  width: 100%;
  background-color: $white;
  padding: 2.5rem;
}

.services-search-container {
  background-color: $color-secondary-5;
  border-radius: 0.63rem;
  padding: 2.5rem;
}

.services-search-title {
  text-align: center;
  margin-bottom: 1rem;
}

.services-search-subtitle {
  ul {
    margin-top: 1rem;
  }
}

.services-location-form {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  text-align: left;
}

.services-location-input-container {
  flex: 1;
  min-width: 0;
}

.services-location-input {
  color: $color-text-primary;
  background-color: $white;
  width: 100%;
  height: 3.12rem;
  outline: 1px solid $color-accent;
  border: 2px solid transparent;
  border-radius: 0.25rem;
  padding: 1rem;

  &:focus {
    background-color: $white;
    outline: none;
    border: 2px solid $color-primary;
    box-shadow: $input-focus-shadow;
  }

  &::placeholder {
    color: $color-text-secondary;
  }
}

.services-input-error {
  margin-top: 0.25rem;
  margin-bottom: -1.55rem;
}

.services-form-submit-text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2.5rem;
}

.services-notify-container {
  display: flex;
  height: fit-content;
}

.services-notify-switch {
  position: relative;
  width: 2.25rem;
  min-width: 2.25rem;
  height: 1.3rem;
  min-height: 1.3rem;
  margin: 0.2rem 1rem 0 0;
}

.services-notify-checkbox {
  width: 0;
  height: 0;
  opacity: 0;

  &:checked + .services-notify-slider {
    background-color: $color-primary;

    &::before {
      -webkit-transform: translate(90%, -50%);
          -ms-transform: translate(90%, -50%);
              transform: translate(90%, -50%);
    }
  }
}

.services-notify-slider {
  position: absolute;
  background-color: $color-accent;
  border-radius: 100vmax;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  -webkit-transition: $long-transition;
  transition: $long-transition;

  &::before {
    position: absolute;
    content: "";
    background-color: $white;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    top: 50%;
    left: 3px;
    transform: translate(0, -50%);
    -webkit-transition: $long-transition;
    transition: $long-transition;
  }
}

.services-notify-subtitle {
  color: $color-text-secondary;
}

.book-service-btn-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.services-search-results-hr {
  border-bottom: 1px solid $black;
  margin: 2.5rem 0;
}

.services-hr {
  border-bottom: 1px solid $color-accent;
  margin: 2.5rem 0;
}

.partner-services-text {
  margin-bottom: 2.5rem;
}

.partner-services-container {
  display: flex;
  flex-direction: column;
  gap: 2.5rem 0;
}

.partner-service-container {
  background-color: $white;
  border-radius: 0.63rem;
  padding: 2rem;
}

.partner-service-inner-container {
  display: flex;
  margin-bottom: 1rem;
}

.partner-service-logo {
  width: 6rem;
  margin-right: 2rem;
  object-fit: contain;
}

.partner-service-info-container {
  width: 100%;
}

.partner-service-info-inner-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
}

.partner-service-name {
  &::after {
    content: "\00a0";
  }
}

.service-tag {
  display: inline-block;
  font-size: $small-text;
  font-weight: $regular;
  color: $black;
  background-color: $color-neutral;
  width: fit-content;
  border-radius: 100vmax;
  padding: 0.1rem 1rem;
}

.partner-service-contact-details {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.services-contact-details-btn-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.services-message-container {
  position: absolute;
  font-size: 1.12rem;
  width: max-content;
  color: $color-text-primary;
  background-color: $white;
  box-shadow: $secondary-shadow;
  border-radius: 0.63rem;
  text-align: center;
  opacity: 0;
  padding: 0.5rem 0.75rem;
  margin-top: 2rem;
  z-index: -1;
  z-index: 100;
}

.partner-service-contact-container-mobile,
.reviews-container-mobile {
  display: none;
}

.review-stars-container {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}

.review-platform-logo {
  width: 1rem;
}

.review-stars {
  display: flex;
}

.review-star {
  color: $color-tertiary;
}

.review-star-empty {
  color: $black;
  opacity: 0.2;
}

.review-star-half {
  position: relative;
  color: rgba(22, 25, 32, 0.2);

  &::after {
    font-family: "Font Awesome 6 Free";
    content: "\f089";
    position: absolute;
    color: $color-tertiary;
    right: 0;
  }
}

.partner-service-description {
  margin-top: 0.5rem;
}


.partner-service-call-now-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.services-more-details {
  display: flex;
  gap: 0.5rem;
  width: fit-content;
  cursor: pointer;
}

.services-show-more-caret {
  position: relative;
  color: $color-text-primary;
  font-size: 1rem;
  height: fit-content;
  transform: rotate(180deg);
  top: 0.15rem;
}

.services-show-more-caret-up {
  transform: rotate(0deg);
  top: 0.2rem;
}

.services-more-details-hr {
  border-bottom: 1px solid $color-accent;
  margin: 1.5rem 0;
}

.partner-service-more-details-inner-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 0.5rem;
}

.partner-service-more-details-category {
  width: calc(50% - 0.25rem);
  height: min-content;
}

.partner-service-more-details-title {
  display: flex;
  flex-wrap: wrap;
  gap: 0 0.38rem;
}

.services-tooltip {
  font-size: 1.12rem;
  color: $color-text-primary;
  cursor: pointer;
}

.services-tooltip-container {
  font-size: 5rem;

  .tooltip.show {
    opacity: 1;
  }

  .tooltip-inner {
    font-family: $main-font;
    font-weight: $light;
    font-size: $small-text;
    line-height: 1.2;
    color: $white;
    background-color: $black;
    min-width: 15rem;
    border-radius: 2px;
    text-align: left;
    margin-left: 0.25rem;
    padding: 0.5rem;
  }

  .arrow {
    margin-top: 0.2rem;
    margin-left: 0.25rem;

    &::after {
      border-right-color: $black !important;
      border-left-color: $black !important;
    }
  }
}

.partner-service-more-details-text {
  white-space: pre-wrap;
}

.partner-service-more-details-list {
  padding-left: 1.5rem;
  margin: 0;

  li a {
    color: inherit;
    text-decoration: underline;
  }
}

// FAQ section
.services-faq-container {
  gap: 1rem;
}

.services-faq-title {
  padding-bottom: 0.5rem;
}

.bookable-services-title {
  margin-top: 2.5rem;
}

// Bookable services
.bookable-service-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  background-color: $white;
  border-radius: 0.63rem;
  margin-top: 2.5rem;
  padding: 1.5rem;
}

.bookable-service-inner-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.bookable-service-title-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.bookable-service-image {
  position: relative;
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 50%;
  border: 1px solid $color-accent;

  &:nth-of-type(2) {
    left: -0.5rem;
  }
}

.services-location-modal-inner-container {
  .services-input-error {
    margin: 0.25rem 0 0 0;
  }
}

.local-services-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2.5rem 0;
}

.local-services-search-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin: 0 auto;
}

.local-services-filter-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.local-services-title {
  text-align: center;
}

.services-checkbox-option {
  margin: 0;
}

// Calling services
.calling-services-container {
  background-color: $white;
  border-radius: 0.63rem;
  padding: 1.5rem;
  margin-top: 2rem;
}

.calling-service-container {
  display: flex;
  gap: 0 2rem;
}

.calling-service-img {
  width: 8.5rem;
  height: 8.5rem;
  border-radius: 50%;
  object-fit: cover;
}

.calling-service-video-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  cursor: pointer;
}

.calling-service-video-container-mobile {
  display: none;
}

.calling-service-icon {
  font-size: 1.3rem;
}

.calling-service-video-text {
  text-decoration: underline;
}

.calling-service-details-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem 0;
}

.calling-service-category {
  color: $color-text-secondary;
}
