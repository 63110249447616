.guidelines-container {
  color: $color-text-primary;
  padding: 3rem;
}

.guidelines-title {
  text-align: center;
}

.guidelines-subtitle {
  margin: 1.5rem 0 0.5rem 0;
}

.guidelines-text-margin {
  margin-top: 1.5rem;
}

.modal-navbar-container-mobile {
  display: none;
}

.guidelines-btn-container-mobile {
  display: none;
  position: fixed;
  background-color: $white;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 1.5rem;
}
