.notification-container {
  position: fixed;
  display: flex;
  width: 100%;
  justify-content: center;
  top: 5.5rem;
}

.notification-inner-container {
  display: flex;
  align-items: center;
  gap: 0 0.6rem;
  color: $black;
  background-color: $white;
  width: 100%;
  max-width: 41rem;
  border-left: 0.5rem solid $color-primary;
  border-radius: 3px;
  box-shadow: 0px 0px 10px 2px rgba(69, 69, 69, 0.2);
  opacity: 0;
  z-index: -1;
  padding: 1rem;
  margin: 0 2rem;
}

.notification-container-subtitle {
  color: $color-text-secondary;
}

.notification-tick {
  font-size: 1.25rem;
  color: $color-secondary-2;
  background-color: $color-primary;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.notification-x {
  font-size: 1.25rem;
  color: $color-danger-secondary;
  background-color: $white;
  border-radius: 50%;
  margin-right: 0.5rem;
}


.notification-container-mobile {
  position: fixed;
  display: none;
  align-items: center;
  gap: 0 0.6rem;
  color: $black;
  width: calc(100% - 2rem);
  height: 3.55rem;
  background-color: $white;
  border-left: 0.5rem solid $color-primary;
  border-radius: 3px;
  box-shadow: 0px 0px 10px 2px rgba(69, 69, 69, 0.2);
  opacity: 0;
  z-index: -1;
  padding: 0.6rem;
  bottom: 1rem;
  left: 1rem;
}
