.custom-modal-container {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    z-index: 105;
    cursor: pointer;
    padding: 0 2.25rem;
    overflow-y: scroll;
    // Hide inner scrollbar while modal is open
    -ms-overflow-style: none; // edge and IE
    scrollbar-width: none; // Firefox
    &::-webkit-scrollbar {
      display: none;  // Chrome
    }
  }
  
.custom-modal {
    position: relative;
    max-width: 56.5rem;
    margin: 0 auto;
    background-color: $white;
    border-radius: 0.63rem;
    margin-top: 7rem;
    overflow: hidden;
    cursor: auto;

    &.modal-small {
      max-width: 33rem;
      border-radius: 10px;
    }
}

.modal-scroll-container {
  min-height: 60rem;
  margin-bottom: 5rem;
}

// feature voting modal non-animation styles
.feature-voting-modal-container {
  .custom-modal {
    max-width: 33.25rem;
  }

  .modal-scroll-container {
    min-height: unset;
    margin-bottom: 1.5rem;
  }
}

.feature-voting-modal-inner-container {
  padding: 3rem;
}

.feature-voting-modal-error-container {
  background-color: $color-secondary-5;
  border: 1px solid $color-secondary-4;
  border-radius: 0.63rem;
  text-align: center;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.feature-voting-title {
  color: $color-primary;
  text-align: center;
}

.feature-voting-subtitle {
  text-align: center;
  white-space: pre-wrap;
  margin-top: 1.5rem;
}

.feature-voting-radio-container {
  display: flex;
  gap: 0 0.5rem;
  margin-top: 2.5rem;
}

.feature-voting-radio-option {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 0 1rem;
  width: 20%;
}

.feature-voting-radio-label {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  color: $color-text-primary;
  text-align: center;
  cursor: pointer;

  &::before {
    content: "";
    display: inline-block;
    position: relative;
    background-color: $white;
    width: 1.25rem;
    min-width: 1.25rem;
    height: 1.25rem;
    border: 2px solid $color-text-primary;
    border-radius: 100%;
    text-align: center;
    cursor: pointer;
  }

  &:hover::before {
    background-color: #4771dc33;
    border: 2px solid $color-text-primary;
    box-shadow: 0px 0px 0px 3px #4771dc33;
    transition: $main-transition;
    -webkit-transition: $main-transition;
  }
}

.feature-voting-radio-face-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.feature-voting-radio-number {
  font-weight: $bold;
  font-size: 0.88rem;
}

.feature-voting-radio-face {
  background-repeat: no-repeat;
  background-size: contain;
  width: 3.3rem;
  height: 3.3rem;
  border-radius: 50%;
  transition: $main-transition;
  -webkit-transition: $main-transition;
  margin-bottom: 0.75rem;

  &:hover {
    transition: $main-transition;
    -webkit-transition: $main-transition;
    box-shadow: $icon-shadow;
  }
}

.feature-voting-radio-container {
  .feature-voting-radio-option {
    cursor: pointer;

    &:hover {
      .feature-voting-radio-face:not(.feature-voting-radio-selected) {
        box-shadow: $icon-shadow;
      }

      .feature-voting-radio-text {
        font-weight: $bold;
      }
    }

    &:nth-child(1) {
      .feature-voting-radio-face {
        background-image: url("https://carecopilot-static-assets.s3.amazonaws.com/fullfrown.png"); 
      
        &.feature-voting-radio-selected {
          background-image: url("https://carecopilot-static-assets.s3.amazonaws.com/fullfrownbold.png");
          background-color: #FFA7A7;
        }
      }

      &:hover {
        .feature-voting-radio-face {
          background-color: #FFA7A7;
        }
      }
    }

    &:nth-child(2) {
      .feature-voting-radio-face {
        background-image: url("https://carecopilot-static-assets.s3.amazonaws.com/frown.png");
    
        &.feature-voting-radio-selected {
          background-image: url("https://carecopilot-static-assets.s3.amazonaws.com/frownbold.png");
          background-color: #FFD6B8;
        }
      }  

      &:hover {
        .feature-voting-radio-face {
          background-color: #FFD6B8;
        }
      }
    }

    &:nth-child(3) {
      .feature-voting-radio-face {
        background-image: url("https://carecopilot-static-assets.s3.amazonaws.com/meh.png");
    
        &.feature-voting-radio-selected {
          background-image: url("https://carecopilot-static-assets.s3.amazonaws.com/mehbold.png");
          background-color: #FFEA9F;
        }
      }      
      
      &:hover {
        .feature-voting-radio-face {
          background-color: #FFEA9F;
        }
      }
    }

    &:nth-child(4) {
      .feature-voting-radio-face {
        background-image: url("https://carecopilot-static-assets.s3.amazonaws.com/smile.png");
    
        &.feature-voting-radio-selected {
          background-image: url("https://carecopilot-static-assets.s3.amazonaws.com/smilebold.png");
          background-color: #D3F1C8;
        }
      }

      &:hover {
        .feature-voting-radio-face {
          background-color: #D3F1C8;
        }
      }
    }

    &:nth-child(5) {
      .feature-voting-radio-face {
        background-image: url("https://carecopilot-static-assets.s3.amazonaws.com/fullsmile.png");
    
        &.feature-voting-radio-selected {
          background-image: url("https://carecopilot-static-assets.s3.amazonaws.com/fullsmilebold.png");
          background-color: #A8ED90;
        }
      }

      &:hover {
        .feature-voting-radio-face {
          background-color: #A8ED90;
        }
      }
    }
  }
}

.feature-voting-radio-selected {
  &:hover {
    box-shadow: none;
  }
}

.feature-voting-radio-text {
  font-weight: $regular;
  font-size: 0.88rem;
  min-height: 2.6rem;
}

.feature-voting-text-selected {
  font-weight: $bold;
}

.feature-voting-radio-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  
  &:checked {
    + .feature-voting-radio-label {
      &::before {
        background-color: $color-primary;
        border-color: $color-primary;
        box-shadow: inset 0 0 0 0.2rem $white;
      }
    }
  }
}

.feature-voting-input-container {
  color: $color-text-primary;
  margin-top: 1.75rem;
}

.feature-voting-highlight-text {
  color: $color-text-secondary;
}

.feature-voting-textarea {
  font-weight: $bold;
  color: $color-text-primary;
  background-color: $white;
  border: 1px solid $color-accent;
  width: 100%;
  height: 3.2rem;
  border-radius: 3px;
  resize: none;
  outline: none;
  overflow-y: hidden;
  padding: 0.8rem 1rem;
  margin-top: 0.5rem;

  &:focus {
    border-color: $color-primary;
    box-shadow: $input-focus-shadow;
  }
}

.feature-voting-textarea-no-value {
  background-color: $color-neutral;
  border: 1px solid $color-neutral;
  box-shadow: $tertiary-shadow;

  &:focus {
    background-color: $white;
    border-color: $color-primary;
    box-shadow: $input-focus-shadow;
  }
}

.default-modal-container {
  .custom-modal {
    width: 33.25rem;
  }

  .modal-scroll-container {
    min-height: unset;
  }
}

.default-modal-inner-container {
  padding: 3rem;
}

.default-modal-title {
  color: $color-primary;
  text-align: center;
  white-space: pre-line;
}

.default-modal-subtitle {
  color: $color-text-primary;
  text-align: center;
  margin-top: 1.5rem;
}

// Feature voting modal animation
.feature-voting-modal-container {
  .custom-modal-container {
    animation: fade-in-modal 0.6s ease;

    &.reverse-animation {
      animation: fade-out-modal 0.5s ease forwards;
    }
  }

  .custom-modal {
    animation: slide-up-modal 0.35s ease;

    &.reverse-animation {
      animation: slide-down-modal 0.35s ease forwards;
    }
  }
}

// Default modal animation
.default-modal-container {
  .custom-modal-container {
    animation: fade-in-modal 0.25s ease;

    &.reverse-animation {
      animation: fade-out-modal 0.2s ease forwards;
    }
  }

  .custom-modal {
    animation: slide-up-modal 0.25s ease;

    &.reverse-animation {
      animation: slide-down-modal 0.2s ease forwards;
    }
  }
}

// Services location modal
.services-location-modal-inner-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 3rem;
}

.services-location-modal-subtitle {
  color: $color-text-primary;
  text-align: center;
}

.services-location-modal-btn-container {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  color: $color-text-primary;
  text-align: center;
}
