.faq-container {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.faq-question-container {
  border-radius: 0.63rem;
  cursor: pointer;
  padding: 1rem;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  a {
    color: inherit;
    font-weight: inherit;
    text-decoration: underline;
  }
}

.faq-caret {
  position: relative;
  color: $color-text-primary;
  font-size: 1rem;
  height: fit-content;
  transform: rotate(180deg);
  top: 0.24rem;
}

.faq-caret-open {
  transform: rotate(180deg);
}

.faq-answer {
  color: $black;
  margin-top: 1rem;
  line-height: 1.55;

  ol, ul {
    padding: 0 1.75rem;
  }

  a {
    color: inherit;
    font-weight: inherit;
    text-decoration: underline;
  }
}

