.navbar-container {
  position: fixed;
  display: flex;
  align-items: center;
  background-color: $white;
  height: 4.5rem;
  width: 100%;
  padding: 0 2rem;
  z-index: 100;
  box-shadow: $main-shadow;
}

.navbar-inner {
  display: flex;
  align-items: center;
  width: 100%;
}

.navbar-links-container {
  display: flex;
  gap: 1.5rem;
  margin-left: 1.5rem;
  margin-right: auto;
}

.link-navbar {
  color: $black;
  float: left;
  border-bottom: 1px solid $white;

  &:hover,
  &:active,
  &:focus:hover {
    border-color: $color-tertiary;
    color: $black;
    font-weight: $semibold;
  }

  &:focus {
    color: $black;
    border-color: $white;
    font-weight: $regular;
  }

  &.link-navbar-current {
    border-bottom: 1px solid $color-tertiary;
    color: $black;
    font-weight: $semibold;
  }
}

.navbar-hamburger-menu {
  display: none;
}

.navbar-hamburger-inner {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 15rem;
  background-color: $white;
  text-align: right;
  border: 1px solid $color-accent;
  border-bottom: none;
  top: 4.4rem;
  right: 1.55rem;
  opacity: 1; 
  visibility: visible; 
  transition: $long-transition; 

  &.hidden { 
    top: 4.3rem;
    opacity: 0; 
    visibility: hidden; 
    transition: $long-transition; 
  }
}

.navbar-container {
  font-family: $main-font;
  display: flex;
  align-items: center;
  background-color: $white;
  height: 4.5rem;
  width: 100%;
  padding: 0 2rem;
  box-shadow: $main-shadow;
}

.navbar-unauthenticated {
  display: flex;
  gap: 1rem;
}

.navbar-user-container {
  display: flex;
  align-items: center;
  padding-left: 2rem;
  margin-left: auto; 
  width: fit-content;   
  cursor: pointer;

  &:hover {
    color: $color-tertiary;
  }
}

.navbar-caret {
  font-size: 1rem; 
  color: $black;
  width: fit-content;
  margin-left: 0.5rem;
}

.navbar-user-container-open {
  .navbar-caret {
    transform: rotate(180deg);
  }
}

.navbar-avatar {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 0.4rem;
}

.navbar-handle {
  color: $black;
  font-size: 1.1rem;
  font-weight: 400;
}
