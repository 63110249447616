// blog landing
.blog-landing-container {
  max-width: 80rem;
  width: 100%;
  margin: 0 auto 4.5rem auto;
  padding: 2.5rem;
}

.blog-recent-container {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 80rem;
  min-height: 20rem;
  box-shadow: $secondary-shadow;
  border-radius: 0.63rem;
  overflow: hidden;
  cursor: pointer;
  top: 0;
  transition: $longer-transition;
  -webkit-transition: $longer-transition;

  &:hover {
    box-shadow: 0px 0px 30px 2px rgba(69, 69, 69, 0.2);
    transition: $longer-transition;
    -webkit-transition: $longer-transition;
    top: -0.5rem;

    .blog-recent-title {
      color: $color-primary-highlight;
      transition: $longer-transition;
      -webkit-transition: $longer-transition;
    }
  }
}

.blog-recent-text-container {
  color: $black;
  width: 50%;
  padding: 3rem;
}

.blog-recent-title {
  margin: 1rem 0;
}

.blog-recent-info-container {
  display: flex;
  gap: 0 0.5rem;
  align-items: center;
  margin-top: 1rem;
}

.blog-recent-img-container {
  width: 50%;
}

.blog-recent-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.blog-hr {
  margin: 2.5rem 0;
  border-color: $color-accent;
}

.blog-cards-container {
  gap: 2.5rem;
  max-width: 75rem;
}

.individual-post-cards-container {
  justify-content: center;
  margin: 0 auto 5.5rem auto;
  padding: 0 2.5rem;
}

.blog-card-container {
  // width is 1/3 of available space minus 1/3 of the total gap
  width: calc(33.3% - 5rem / 3);
  box-shadow: $secondary-shadow;

  &:hover {
    top: -0.5rem;
  }
}

.blog-card-img {
  border-radius: 0;
}

.blog-card-text-container {
  padding: 1.5rem;
}

.blog-posts-explore-title {
  text-align: center;
  padding-bottom: 3rem;
}

// blog posts
.blogpost-container {
  flex: 1;
  max-width: 44rem;
  color: $black;
  background-color: $white;
  margin: 0 auto 7rem auto;
}

.blogpost-title {
  color: $black;
  margin-top: 3rem;
}

.blogpost-info-container {
  display: flex;
  gap: 0 0.75rem;
  color: $black;
  margin-top: 1.5rem;
}

.blogpost-separator-dot {
  line-height: 0.75;
}

.blogpost-main-text-container {
  line-height: 1.7;
}

.blogpost-img {
  width: 44rem;
  height: 29rem;
  object-fit: cover;
  border-radius: 0.63rem;
  margin-top: 3rem;
}

.blogpost-h2 {
  font-family: inherit;
  font-size: 1.5rem;
  font-weight: $semibold;
  line-height: 1.75rem;
  margin-top: 4.5rem;
}

.blogpost-p {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: 1.7;
  margin-top: 3rem;
}

.blogpost-a {
  font-family: inherit;
  color: $color-text-primary;
  font-size: inherit;
  font-weight: inherit;
  cursor: pointer;
  text-decoration: underline;

  &:link,
  &:visited,
  &:hover {
    text-decoration: underline;
  }

  &:hover {
    color: $color-primary;
  }
}

.blogpost-blockquote {
  font-style: italic;
  line-height: 1.7;
  margin-top: 3rem;
}

.blogpost-strong {
  display: block;
  font-weight: $bold;
  margin-top: 2rem;
}

.blogpost-span {
  font-weight: $bold;
}
