.footer-container {
  display: flex;
  align-items: center;
  gap: 1.5rem 1.8rem;
  background-color: $color-primary;
  background-attachment: fixed;
  padding: 2.5rem 5rem;
  min-height: 16.75rem;
}

.footer-nav-container {
  display: flex;
  gap: 0.5rem 1.5rem;
  padding: 0.5rem 0;
}

.footer-nav-link {
  color: $white;
  
  &:hover {
    font-weight: $bold;
    color: $white;
    text-decoration: underline;
  }
}

.footer-icons-container {
  display: flex;
  gap: 0 1rem;
  margin-left: auto;
}

.footer-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-primary;
  background-color: $white;
  border-radius: 50%;
  font-size: 1.25rem;
  width: 2.35rem;
  height: 2.35rem;
  transition: $main-transition;
  -webkit-transition: $main-transition;

  &:hover,
  &:active {
    color: $color-tertiary;
    transition: $main-transition;
    -webkit-transition: $main-transition;
  }

  &:focus {
    color: $white;
  }

  &:focus:hover {
    color: $color-tertiary;
  }
}
