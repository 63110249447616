body,
h1,
h2,
h3,
h4,
h5,
p,
a,
label {
  font-family: $main-font;
  font-weight: $regular;
  margin: 0;
}

a {
  &:hover {
    text-decoration: none;
  }
}

body {
  background-color: $white;
}
