

@media screen and (max-width: 1750px) {
  .home-hero-section {
    background-position: right -14rem bottom 0;
  }

  .home-hero-text-container {
    max-width: 57%;
    margin: 0 auto 0 0;
  }
}

@media screen and (max-width: 1600px) {
  .home-advice-title {
    white-space: pre-wrap;
  }

  .home-copilots-section {
    background-position: 30% 50%;
  }
}

@media screen and (max-width: 1450px) {
  .home-hero-text-container {
    max-width: 50%;
  }

  .home-hero-link-container {
    * {
      white-space: pre-wrap;
    }
  }
}

@media screen and (max-width: 1345px) {
  // care copilots
  .carecopilots-page-container {
    padding: 7rem 4rem 9rem 4rem;
  }
  // end of care copilots

  // pricing
  .pricing-page-container {
    padding: 7rem 4rem 9rem 4rem;
  }

  .pricing-card-container {
    padding: 3rem 2rem 2rem 2rem;
  }
  // end of pricing
}

@media screen and (max-width: 1330px) {
  // footer
  .footer-container {
    flex-wrap: wrap;
    height: 20.75rem;
    padding: 7.5rem 5rem;
  }

  .footer-logo-container {
    width: 100%;
  }
  // end of footer
}

@media screen and (max-width: 1270px) {
  // blog
  .blog-card-container {
    // each card takes up half the available width minus half the gap
    width: calc(50% - 1.25rem);
  }
  // end of blog

  // forum page
  .forum-page-container {
    width: unset;
    margin: 0 1.95rem 2.25rem 1.95rem;
  }

  .forum-main-content-container {
    width: 100%;
  }

  .forum-post-reaction-bar {
    padding: 0;
  }
  // end of forum page

  // home
  .home-hero-section {
    background-position: right -16.5rem bottom 0;
  }
  // end of home
}

@media screen and (max-width: 1250px) {
  .home-hero-section {
    padding: 4.75rem 4rem 5.75rem 4rem;
  }

  .home-advice-section {
    padding: 5.5rem 4rem 7rem 4rem;
  }

  .home-card-text-container {
    height: fit-content;
  }

  .home-copilots-section {
    background-position: 30% 70%;
    padding: 7rem 4rem;
  }

  .home-copilots-content-container,
  .home-practical-help-text-containers,
  .home-mission-content-container {
    flex-direction: column;
  }

  .home-copilots-text-container,
  .home-copilots-container,
  .home-practical-help-list-container,
  .home-mission-text-container,
  .home-mission-video-container {
    width: 100%;
  }

  .home-practical-help-section {
    padding: 7rem 4rem;
  }

  .home-practical-help-title-container {
    width: 100%;
    max-width: 50rem;
  }

  .btn-schedule-call-desktop {
    display: none;
  }

  .btn-schedule-call-mobile {
    display: block;
  }

  .home-mission-section {
    padding: 7rem 4rem;
  }

  .home-backed-by-container {
    padding: 5rem 4rem;
  }
}

@media screen and (max-width: 1150px) {
  .home-hero-section {
    align-items: flex-start;
    flex-direction: column;
    background-image: none;
    height: unset;
    padding: 0;
  }

  .home-hero-img-mobile {
    background-image: url("https://carecopilot-static-assets.s3.amazonaws.com/home-hero.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right -3rem bottom 0;
    width: 100%;
    max-height: 50rem;
    height: 70vw;
    margin: 0 auto;
  }

  .home-hero-text-container {
    width: 100%;
    max-width: 100%;
    padding: 4.75rem 4rem 0.5rem 4rem;
  }

  .home-hero-link-container {
    * {
      white-space: initial;
    }
  }

  .home-hero-title,
  .home-hero-subtitle {
    max-width: 100%;
  }

  .home-advice-title {
    white-space: initial;
  }
}

@media screen and (max-width: 1142px) {
  .account-details-row-container-long {
    flex-direction: column;
  }
}

@media screen and (max-width: 1100px) {
  // navbar
  .btn-navbar {
    font-size: 1.05rem;
  }
  // end of navbar
}

@media screen and (max-width: 1050px) {
  .account-details-title-container {
    display: flex;

    .slide-up-slide-down-long {
      margin: 0;
    }
  }
}

@media screen and (max-width: 1035px) {
  // navbar
  .navbar-links-container {
    gap: 1.25rem;
  }

  .btn-navbar {
    padding: 0.25rem 2rem;
  }
  // end of navbar

  // footer
  .footer-container {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: center;
    padding: 0 5rem;
  }

  .footer-icons-container {
    margin: 0;
  }
  // end of footer

  // forum
  .forum-post-reaction-bar {
    gap: 0.75rem 0.65rem;
  }
  // end of forum
}

// this cannot be reduced to 991px due to a bug with firefox that displays the bootstrap col-md layout at 991px but doesn't display the 991px media queries until 990px
@media screen and (max-width: 991.5px) {
  // care copilots
  .carecopilot-container {
    flex-direction: column;
    align-items: center;
  }

  .carecopilot-name,
  .carecopilot-role {
    text-align: center;
  }
  // end of care copilots

  // navbar
  .navbar-container,
  .navbar-positioning {
    height: 3.5rem;
    padding: 0 1.25rem;
  }

  .content-container {
    margin: 3.5rem 0 0 0;
  }

  .navbar-links-container,
  .navbar-unauthenticated {
    display: none;
  }

  .navbar-inner {
    justify-content: space-between;
  }

  .navbar-hamburger-inner {
    top: 3.5rem;
    right: 1.5rem;

    &.hidden {
      top: 3.5rem;
    }
  }

  .navbar-hamburger-menu {
    display: block;
    font-size: 1.25rem;
    transform: scale(1.2, 1);
    cursor: pointer;
  }
  // end of navbar

  // contact page
  .contact-container {
    width: 42rem;
  }

  .contact-row-container {
    flex-direction: column;
  }

  .contact-input {
    width: 100%;
  }
  // end of contact page

  // forum page
  .forum-main-content-container {
    flex-direction: column;
  }

  .forum-guidelines-container,
  .forum-sidebar-tags-container {
    display: none;
  }

  .forum-guidelines-container-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .forum-sidebar-container {
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    max-width: unset;
    min-width: unset;
    width: 100%;
    top: 0;
    margin: 0;
  }

  .forum-searchbar-container {
    min-width: 10rem;
    width: 50%;
  }

  .forum-searchbar {
    min-height: 100%;
  }

  .forum-sidebar-dropdown {
    display: unset;
    min-width: 50%;
    width: 50%;
  }

  .forum-post-list-container {
    max-width: unset;
    width: 100%;
    margin: 1.5rem 0 0 0;
  }

  .forum-post-modal {
    max-width: 100%
  }

  .custom-modal-container {
    padding: 0 1.5rem;
  }

  .forum-create-post-container {
    top: 5.25rem;
  }

  .forum-create-post-overlay {
    height: 8.8rem;
    top: 3.5rem;
  }

  .forum-post-interactions-container {
    gap: 0.75rem 1rem;
  }
  // end of forum page
}

@media screen and (max-width: 961px) {
  .account-details-title-container {
    display: inline-block;

    .slide-up-slide-down-long {
      margin-top: -1.65rem;
    }
  }
}

@media screen and (max-width: 950px) {
  // home
  .home-advice-title-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .home-advice-cards-container {
    gap: 4rem;
  }

  .home-card-container {
    flex-direction: column;
    align-items: center;
  }

  .home-card-img-container,
  .home-card-text-container {
    width: 100%;
    max-width: 32.5rem;
  }

  // services
  .calling-service-container {
    gap: 0 1rem;
  }

  .calling-service-img {
    width: 5rem;
    height: 5rem;
  }

  .calling-service-video-container {
    justify-content: flex-start;
    margin: 0;

    &:not(.calling-service-video-container-mobile) {
      display: none;
    }
  }

  .calling-service-video-container-mobile {
    display: flex;
  }
  // end of services
}

@media screen and (max-width: 947px) {
  .explore-more-cards-container {
    justify-content: flex-start;

    .card-container {
      flex: 1;
    }
  }
}

@media screen and (max-width: 925px) {
  // account page
  .account-details-row-container {
    flex-direction: column;
  }
  // end of account page
}

@media screen and (max-width: 910px) {
  .navbar-inner {
    padding: 0;
  }
}

@media screen and (max-width: 900px) {


  // pricing
  .pricing-card-container {
    min-width: 100%;
    max-width: 100%;

    &:nth-of-type(3) {
      max-width: 100%;
    }
  }

  // services
  .services-navigation-text {
    font-size: 1.12rem;
    line-height: 1.4rem;
  }

  .partner-service-container {
    padding: 1rem;
  }

  .partner-service-inner-container {
    align-items: flex-start;
  }

  .partner-service-logo {
    width: 3rem;
    margin: 0 1rem 0 0;
  }

  .partner-service-contact-details-container,
  .review-stars-container {
    display: none;
  }

  .partner-service-inner-container {
    margin: 0 0 0.5rem 0;
  }

  .partner-service-info-inner-container {
    margin: 0;
  }

  .partner-service-contact-container-mobile {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .reviews-container-mobile {
    display: flex;
    flex-wrap: wrap;
    gap: 0 1rem;
  }

  .review-container-mobile {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
  // end of services
}

@media screen and (max-width: 870px) {
  // blog
  .blog-landing-container {
    padding: 1rem;
  }

  .blog-recent-container,
  .blog-hr {
    display: none;
  }

  .blog-cards-container {
    gap: 1.5rem;
  }

  .blog-card-container {
    min-width: 100%;
  }
  // end of blog

  // forum
  .forum-single-post-container {
    width: unset;
    margin: 4rem 1.95rem 8rem 1.95rem;
  }
  // end of forum

  // services
  .bookable-service-inner-container {
    flex-direction: column;
    align-items: flex-start;
  }
  // end of services
}

@media screen and (max-width: 860px) {
  .local-services-filter-container {
    justify-content: center;
  }
}

@media screen and (max-width: 858px) {
  // account page
  .account-details-inner-container {
    padding: 3rem 2.5rem;
  }
  // end of account page

  // home
  .home-backed-by-img-container {
    gap: 4.5rem;
  }
  // end of home
}

@media screen and (max-width: 830px) {
  // account page
  .account-container {
    flex-direction: column;
    margin: 0.5rem 0 0 0;
  }

  .account-completion-container {
    width: 100%;
    height: unset;
    min-height: 8rem;
    padding: 1.5rem;
  }

  .completion-donut-container-mobile {
    display: flex;
  }

  .completion-donut-container {
    min-width: 5rem;
    width: 5rem;
    min-height: 5rem;
    height: 5rem;
    margin: -0.3rem 1rem 0 0;
  }

  .completion-percentage {
    font-size: 0.6rem;
  }

  .completion-section-hr {
    margin: 1.5rem 0;
  }

  .completion-section {
    margin: 0;
  }

  .account-title {
    font-size: 1.5rem;
    padding: 1.5rem;
  }

  .account-details-inner-container {
    padding: 1.5rem 1.5rem 5rem 1.5rem;
  }

  .account-details-row-container {
    flex-direction: row;
  }
  // end of account page
}

@media screen and (max-width: 815px) {
  // forum
  .forum-post-reaction-bar {
    gap: 0.75rem 1rem;
  }

  .forum-post-likes-comments-container {
    gap: 0.75rem 0.5rem;
  }
  // end of forum
}

@media screen and (max-width: 790px) {
  // blog post
  .blogpost-container {
    max-width: unset;
    margin: 0 1rem 7rem 1rem;
  }

  .blogpost-title {
    font-size: 1.75rem;
    line-height: 2rem;
  }

  .blogpost-img {
    width: 100%;
    height: auto;
  }
  // end of blog post

  //faq
  .faq-container {
    max-width: unset;
    margin: 0 1rem 7rem 1rem;
  }

  .faq-title {
    font-size: 1.75rem;
    line-height: 2rem;
    text-align: center;
  }
  //end of faq

  // forum page
  .forum-posting-suspended-container {
    margin: 0;
  }

  .forum-posting-suspended-text,
  .forum-posting-suspended-link {
    font-size: 1rem;
  }

  .default-modal-container {
    .custom-modal {
      max-width: 23rem;
      width: unset;
    }
  }

  .default-modal-btn-container {
    flex-direction: column;
    align-items: center;
  }

  .default-modal-subtitle {
    white-space: pre-wrap;
  }
  // end of forum page
}

@media screen and (max-width: 767px) {
  // care copilots
  .carecopilot-img {
    width: 12.5rem;
    height: 12.5rem;
  }
  // end of care copilots

  // home
  .home-backed-by-img-container {
    gap: 3.5rem 4.5rem;
  }

  .img-techstars {
    width: 14rem;
  }

  .img-fearless-fund {
    width: 8.5rem;
  }

  .img-aarp {
    width: 9rem;
  }

  .img-google-for-startups {
    width: 13.5rem;
  }

  .img-visible-hands {
    width: 15rem;
  }

  .img-aws {
    width: 7.5rem;
  }
  // end of home

  //navbar
  .btn-hamburger {
    font-size: 1rem;
    padding: 0.6rem 1.5rem;
  }
  //end of navbar

  // contact page
  .contact-container {
    width: unset;
    margin: 1.5rem;
  }

  .contact-header {
    font-size: 2.25rem;
  }

  .contact-subheader {
    font-size: 1.25rem;
    line-height: 1.2;
  }

  .contact-input-label {
    font-size: 1.15rem;
  }

  .contact-submission-success {
    font-size: 0.9rem;
  }
  // end of contact page

  // error 404
  .error-404-container {
    margin: 11.5rem auto 15.5rem auto;
  }

  .error-404-body {
    margin: 0.75rem 0;
  }

  .btn-return-home {
    font-size: 0.95rem;
    padding: 1.2rem 1.35rem;
    margin: 1.4rem auto;
  }
  // end of error 404

  // services
  .services-location-form {
    flex-direction: column;
    gap: 0.5rem;
    margin: 1rem 0 0 0;
  }

  .services-location-input-container,
  .services-location-input {
    width: 100%;
  }

  .btn-submit-services-location {
    width: 100%;
    margin-top: 0.5rem;
  }

  .services-input-error {
    margin: 0;
  }
  // end of services
}

@media screen and (max-width: 730px) {
  // forum
  .forum-post-likes-comments-container {
    width: 100%;
  }

  .forum-post-interactions-container {
    margin: 0;
  }

  .btn-comments {
    margin: 0;
  }
  // end of forum
}

@media screen and (max-width: 715px) {
  .resources-card-img {
    max-height: 13.75rem;
  }
}

@media screen and (max-width: 700px) {
  // account
  .account-details-row-container-long {
    flex-direction: column;
  }

  .account-tooltip-container {
    & .tooltip-inner {
      min-width: 17rem;
    }
  }
  // end of account

  // resources
  .resources-author-btn-container {
    flex-direction: column;
  }

  .btn-author-bio {
    width: 100%;
    max-width: 18rem;
  }

  .resources-author-container {
    gap: 1rem 1.5rem;
  }
  // end of resources
}

@media screen and (max-width: 670px) {
  // footer
  .footer-container {
    align-items: center;
    height: 23.9rem;
    padding: 2.5rem 1rem;
  }

  .footer-logo-container {
    text-align: center;
  }

  .footer-logo {
    width: 13.3rem;
  }

  .footer-nav-container {
    flex-direction: column;
    font-size: 1rem;
    text-align: center;
  }

  .footer-icon {
    font-size: 1.12rem;
    width: 1.87rem;
    height: 1.87rem;
  }
  // end of footer
}

@media screen and (max-width: 642px) {
  .resource-page-container {
    margin: 2.5rem auto 0 auto;
  }

  .blogpost-container {
    margin: 0 1rem;
  }

  .explore-more-cards-section {
    gap: 1rem;
    background-color: $color-secondary-5;
    // Use negative margin to allow background color to show through parent's margin
    padding: 2.5rem 1rem 3.5rem 1rem;
    margin: 1.5rem -1rem 0 -1rem;

    .card-container {
      width: 100%;
      max-width: unset;
    }

    .card-text-container {
      gap: 0;
      background-color: $color-secondary-5;
      padding: 0.5rem 0;
    }
  }

  .resources-author-container {
    padding: 3rem 0 2rem 0;
  }
}

@media screen and (max-width: 630px) {
  // forum page
  .forum-post-expert-badge {
    font-size: 0.77rem;
    width: 3.75rem;
  }

  .forum-post-reaction-bar {
    gap: 0.75rem 1.15rem;
    margin: 0.5rem 0 0 0;
  }
  // end of forum page

  // onboarding
  .onboarding-page-container {
    padding: 0;
  }

  .onboarding-container {
    margin: 3.25rem 3rem;

    .account-section-title,
    .account-radio-option,
    .account-radio-text-input {
      font-size: 1rem;
    }
  }

  .onboarding-title {
    font-size: 1.4rem;
  }

  .onboarding-subtitle {
    font-size: 1rem;
    margin: 0.75rem 0 0.5rem 0;
  }

  .onboarding-label {
    font-size: 1rem;
    line-height: 1.4;
  }

  .onboarding-tooltip-container {
    & .tooltip-inner {
      min-width: 13.5rem;
    }
  }
  // end of onboarding

  // resources
  .resource-description {
    font-size: 1.12rem;
  }

  .instructional-videos-info-container {
    flex-direction: column;
    align-items: flex-start;
  }
  // end of resources
}

@media screen and (max-width: 599px) {
  .account-details-title-container {
    display: flex;

    .slide-up-slide-down-long {
      margin: 0;
    }
  }
}

@media screen and (max-width: 580px) {
  // blog
  .blog-posts-explore-title {
    font-size: 1.5rem;
    font-weight: $bold;
  }
  // end of blog

  // care copilots
  .carecopilots-page-container {
    padding: 3.25rem 1.5rem;
  }

  .carecopilots-title {
    font-size: 2rem;
  }

  .carecopilots-subtitle,
  .carecopilot-role {
    font-size: 1.12rem;
  }

  .carecopilots-container {
    margin: 3rem 0 0 0;
  }

  .carecopilot-container {
    gap: 0.5rem;
    padding: 1.5rem;
  }

  .carecopilot-img {
    width: 10.5rem;
    height: 10.5rem;
  }

  .carecopilot-text-container {
    gap: 0.5rem;
  }

  .carecopilot-name {
    font-size: 1.63rem;
  }

  .carecopilot-description {
    font-size: 1rem;
  }
  // end of care copilots

  .faq-question-container {
    margin-top: 2rem;
  }

  .faq-question {
    font-size: 1.12rem;
    line-height: 1.4;
    padding: 0 1.25rem 0 0;
  }

  // feature voting
  .feature-voting-modal-container {
    .custom-modal-container {
      padding: 0 1rem;
    }

    .custom-modal {
      max-width: 24rem;
    }
  }

  .feature-voting-modal-inner-container {
    padding: 3rem 1.5rem;
  }

  .feature-voting-title {
    white-space: pre-wrap;
  }

  .feature-voting-subtitle {
    white-space: normal;
  }

  .feature-voting-radio-face {
    width: 2.5rem;
    height: 2.5rem;
  }

  .feature-voting-radio-text {
    position: absolute;
    top: 3.25rem;
    font-size: 0.75rem;
  }

  .feature-voting-radio-number {
   ~ .feature-voting-radio-text {
      top: calc(100% + 0.5rem);
      min-width: 3rem;
    }
  }

  .feature-voting-input-container {
    margin-top: 5rem;
  }

  .notification-container {
    display: none;
  }

  .notification-container-mobile {
    display: flex;
  }
  // end of feature voting

  // forum
  .forum-page-container {
    margin: 0 0 2.25rem 0;
  }

  .forum-main-content-container {
    margin: 0;
  }

  .forum-sidebar-container {
    flex-direction: column;
    gap: 1rem;
    min-width: unset;
    border-radius: 0;
    padding: 1rem;

    margin-top: 0.5rem;
  }

  .forum-searchbar-container {
    width: 100%;
  }

  .forum-sidebar-dropdown {
    min-width: 100%;
    width: 100%;
  }

  .forum-interactions-modal-container {
    display: block;

    .custom-modal-container {
      max-height: 100vh;
      padding: 0;
    }

    .modal-scroll-container {
      min-height: unset;
      margin: 0;
    }

    .custom-modal {
      position: absolute;
      bottom: 0;
      border-radius: 7px 7px 0 0;
      width: 100%;
      animation: slide-into-view 0.2s;
      -webkit-animation: slide-into-view 0.2s;

      &.slide-out {
        animation: slide-out 0.15s forwards;
        -webkit-animation: slide-out 0.15s forwards;
      }
    }
  }

  .forum-interactions-modal-inner-container {
    width: 100%;
  }

  .forum-create-post-container {
    position: static;
    margin: 0 0 0.5rem 0;
  }

  .forum-create-post-overlay {
    display: none;
  }

  .forum-post-list-container {
    margin: 0.5rem 0 0 0;
  }

  .forum-navigation-bar {
    gap: 0;
    background-color: $color-neutral;
    border-radius: 0;
    padding: 0.5rem;
    margin: 0 0 0.5rem 0;
  }

  .btn-forum-navigation {
    font-size: 1rem;
    background-color: $color-neutral;
    border-radius: 0.625rem;
    box-shadow: none;
  }

  .btn-forum-navigation-current {
    color: $color-text-primary;
    background-color: $color-secondary-6;
  }

  .forum-message-tick:not(.forum-image-uploaded-icon) {
    font-size: 1.4rem;
    margin: 0;
  }

  .forum-creator-bar-info-container,
  .forum-post-when-posted,
  .forum-post-title,
  .forum-post-body,
  .forum-post-tags-container,
  .forum-post-no-tag {
    font-size: 1rem;
  }

  .forum-post-modal-container {
    .custom-modal-container {
      padding: 0 1rem;
    }
  }

  .forum-post-modal-title,
  .btn-forum-new-post {
    margin: 1rem 0 0 0;
  }

  .forum-image-upload-text {
    margin: 1rem auto 0 0;
  }

  .forum-post-ellipsis {
    display: inline;
  }

  .forum-comment-actions-container {
    display: flex;
    flex-direction: column-reverse;
    margin-top: -1.45rem;

    // Offset the negative margin needed by the actions container
    .forum-uploaded-img {
      margin-top: 2rem;
    }
  }

  .btn-image-upload-error {
    font-size: $medium-text;
  }

  .forum-image-upload-error {
    font-size: $small-text;
    width: fit-content;
  }

  .forum-comment-image-upload-text {
    margin: 0 auto 0 0;
  }

  .forum-guidelines-container-mobile {
    margin: 0 0.5rem;
  }

  .guidelines-container {
    padding: 1.5rem;
  }

  .guidelines-title {
    font-size: 1.5rem;
    font-weight: $bold;
  }

  .guidelines-mobile-container {
    .custom-modal,
    .modal-scroll-container {
      border-radius: 0;
      margin: 0;
    }

    .custom-modal-container {
      padding: 0;
    }
  }

  .modal-navbar-container-mobile {
    display: block;
  }

  .guidelines-btn-container-mobile {
    display: flex;
    gap: 1rem;
  }

  .forum-search-results-header {
    margin: 0.5rem 0 1rem 1rem;
  }

  .forum-post-container {
    border-radius: 0;
    padding: 1rem;
  }

  .forum-post-avatar {
    min-width: 2rem;
    max-width: 2rem;
    min-height: 2rem;
    max-height: 2rem;
  }

  .forum-post-body {
    margin: 0.5rem 0;
  }

  .forum-post-reaction-bar {
    margin: 1rem 0 0 0;
  }

  .forum-post-interactions-container {
    display: none;
  }
  // end of forum

  // home
  .home-hero-text-container {
    gap: 1.5rem;
    padding: 2.5rem 1.5rem 1rem 1.5rem;
  }

  .home-hero-title {
    font-size: 2rem;
  }

  .home-hero-subtitle {
    font-size: $medium-text;
  }

  .home-hero-link-container {
    display: none;
  }

  .home-hero-img-mobile {
    background-position: right -1.5rem bottom 0;
    width: 100%;
    max-height: 50rem;
    height: 70vw;
    margin: 0 auto;
  }

  .home-advice-section {
    padding: 3.25rem 1.25rem;
  }

  .home-advice-subtitle {
    font-size: 1.12rem;
  }

  .home-advice-title {
    font-size: 2rem;
  }

  .home-advice-title-link,
  .home-card-img-tag {
    font-size: $medium-text;
  }

  .home-advice-cards-container {
    gap: 3.5rem;
    margin: 2.5rem 0 0 0;
  }

  .home-copilots-section {
    background-position: 35% 40%;
    padding: 3.25rem 1.5rem;
  }

  .home-copilots-content-container {
    gap: 2.5rem;
  }

  .home-copilots-title {
    font-size: 2rem;
  }

  .home-copilots-subtitle {
    font-size: $medium-text;
  }

  .home-copilots-container {
    gap: 1.5rem;
  }

  .home-copilot-container {
    gap: 0.5rem;
  }

  // Force name height to be 2 lines
  .home-copilot-name {
    min-height: 3.56rem;
  }

  .home-copilot-img {
    width: 7.5rem;
    height: 7.5rem;
  }

  .btn-home-copilot {
    margin-top: auto;
  }

  .home-practical-help-section {
    gap: 2.5rem;
    padding: 3.25rem 1.5rem;
  }

  .home-practical-help-title {
    font-size: 2rem;
  }

  .home-practical-help-list-title {
    font-size: 1rem;
  }

  .home-practical-help-text-containers {
    gap: 2rem;
  }

  .home-practical-help-title-container {
    gap: 1rem;
  }

  .home-practical-help-list-container {
    padding: 1.5rem;
  }

  .home-practical-help-list {
    li {
      gap: 1rem;
      font-size: 1rem;
      margin: 1.5rem 0 0 0.5rem;
    }
  }

  .home-practical-help-tick {
    font-size: 1.25rem;
  }

  .btn-schedule-call,
  .btn-schedule-call-mobile {
    font-size: 1.12rem;
    font-weight: $regular;
    width: 100%;
    text-align: center;
    padding: 0.6rem 1rem;
  }

  .home-mission-section {
    padding: 3.25rem 1.5rem;
  }

  .home-mission-content-container {
    gap: 2.5rem;
  }

  .home-mission-text-container {
    gap: 0.5rem;
  }

  .home-mission-title {
    font-size: 2rem;
    margin: 0 0 0.5rem 0;
  }

  .home-mission-text {
    font-size: 1rem;
  }

  .home-backed-by-section {
    position: relative;
  }

  .home-backed-by-container {
    gap: 2.5rem;
    padding: 3.25rem 2rem;
  }

  .home-backed-by-title {
    font-size: 2rem;
  }

  .home-backed-by-img-container {
    gap: 2.5rem 4rem;
  }

  .img-techstars {
    width: 10.5rem;
  }

  .img-fearless-fund {
    width: 7rem;
  }

  .img-aarp,
  .img-aws {
    width: 6rem;
  }

  .img-google-for-startups {
    width: 10rem;
  }

  .img-visible-hands {
    width: 8.9rem;
  }

  .home-backed-by-arch {
    position: absolute;
    // Offset occasional rendering issues by raising the arch by 1px
    transform: translateY(-1px);
  }

  .home-signup-section {
    position: relative;
    text-align: center;
    margin: 4.75rem auto 3.25rem auto;
    padding: 0 1.5rem;
  }

  .home-signup-text {
    font-size: 2rem;
  }
  // end of home

  // pricing
  .pricing-page-container {
    padding: 3.25rem 1.5rem;
  }

  .pricing-title {
    font-size: 2rem;
  }

  .pricing-subtitle {
    font-size: 1.12rem;
  }

  .pricing-cards-container {
    margin: 3rem auto 0 auto;
  }

  .pricing-card-container {
    padding: 2.5rem 1.5rem 1.5rem 1.5rem;
  }
  // end of pricing

  // resources
  .resources-author-container {
    flex-direction: column;
    align-items: center;
    padding: 3rem 0.5rem 2rem 0.5rem;
  }

  .resources-author-img {
    width: 9rem;
    height: 9rem;
  }

  .resources-author-text-container {
    align-items: center;
  }

  .resources-author-btn-container {
    align-items: center;
    width: 100%;
  }
  // end of resources

  // services
  .services-page-container {
    padding: 1.5rem 0;
  }

  .services-title {
    font-size: 1.5rem;
    font-weight: $bold;
    padding: 0 1.5rem;
  }

  .services-subtitle {
    font-size: 1.12rem;
    padding: 0.5rem 1.5rem 0 1.5rem;
    margin: 0;
  }

  .services-content-container {
    width: 100%;
    margin: 2rem 0 0 0;
  }

  .services-navigation-option {
    gap: 0.4rem 0;
    height: unset;
    padding: 1.25rem 0.25rem 1rem 0.25rem;
  }

  .services-navigation-text {
    font-size: 0.88rem;
    font-weight: $regular;
  }

  .services-navigation-option-selected {
    .services-navigation-text {
      font-weight: $bold;
    }
  }

  .services-navigation-icon-container {
    min-height: 2.8rem;
    width: 2.8rem;
    height: 2.8rem;
  }

  .services-navigation-icon {
    width: 1.6rem;
    height: 1.6rem;
  }

  .in-home-care-container {
    padding: 2rem 1rem;
  }

  .services-search-container {
    padding: 1.5rem;
  }

  .services-search-title {
    font-size: 1.12rem;
  }

  .services-search-subtitle,
  .services-notify-text {
    font-size: 1rem;
  }

  .services-hr {
    margin: 2rem 0;
  }

  .local-services-title,
  .btn-service-location {
    font-size: 1.12rem;
  }

  .local-services-container {
    gap: 1rem;
  }

  .local-services-search-container {
    gap: 0 0.5rem;
  }

  .local-services-filter-container {
    font-size: 0.87rem;
  }

  .btn-multi-select-services {
    font-size: 0.87rem;
    padding: 0.15rem 0.7rem;
  }

  .calling-services-container {
    padding: 1rem;
    margin: 1rem -0.25rem 0 -0.25rem;
  }

  .calling-service-details-container {
    gap: 0.25rem 0;
  }

  .calling-service-category-container {
    display: none;
  }

  .calling-service-name {
    font-size: 1.12rem;
    line-height: 1.4;
  }

  .calling-service-role {
    line-height: 1.5;
  }

  .calling-service-video-text,
  .calling-service-role,
  .calling-service-description {
    font-size: 1rem;
  }

  .btn-calling-services {
    margin: 1rem auto 0 auto;
  }

  .calling-service-description {
    margin: 0.75rem 0 0 -6rem;
  }

  .faq-question-text {
    font-size: 1rem;
  }

  .faq-answer {
    font-size: 1rem;
    margin: 0.6rem 0 0 0;
  }

  .btn-services-help {
    background-color: $color-secondary-2;
    border-color: $color-secondary-2;
    bottom: 0;
    left: 100%;
    transform: translate(-40%, -50%);
    // negative margin to account for height of button
    margin: 0 0 -2rem 0;
    padding: 0.5rem;

    i {
      font-size: 1.5rem;
    }
  }

  .btn-services-help-text {
    display: none;
  }

  .partner-services-text {
    margin: 0 0 1rem 0;
  }

  .partner-services-container {
    gap: 1rem;
  }

  .partner-service-container {
    // create effect of 1rem padding while allowing form to keep 1.5rem padding
    margin: 0 -0.25rem;
  }

  .service-tag {
    display: none;
  }

  .partner-service-more-details-inner-container {
    flex-direction: column;
  }

  .partner-service-more-details-category {
    width: 100%;
  }

  .partner-service-call-now-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem 0;
  }

  .btn-services-call-now {
    width: 100%;
    text-align: center;
  }

  .bookable-service-title {
    font-size: 1.12rem;
  }

  .bookable-service-description {
    font-size: 1rem;
  }

  .btn-calling-services {
    width: 100%;
  }
  // end of services
}

@media screen and (max-width: 551px) {
  // error 404
  .error-404-container {
    margin: 11.5rem 1.4rem 15.5rem 1.4rem;
    max-width: 100%;
  }
  // end of error 404

  // forum
  .forum-post-comments-text,
  .btn-unsave-post,
  .btn-edit-post,
  .btn-delete-post {
    display: none;
  }
  // end of forum
}

@media screen and (max-width: 540px) {
  // onboarding
  .onboarding-container {
    width: unset;
    margin: 2.75rem 2.5rem;
    padding: 2.25rem;

    .account-radio-other-option {
      align-items: flex-start;
      flex-direction: column;

      &::before {
        position: absolute;
      }
    }

    .account-radio-other-title {
      margin: 0 0 0 2.25rem;
    }

    .account-radio-text-input {
      width: 100%;
      margin: 0;
    }
  }
  // end of onboarding
}

@media screen and (max-width: 500px) {
  // blog post
  .blogpost-container {
    margin: 0;

    .explore-more-cards-section {
      padding: 2.5rem 1rem 3.5rem 1rem;
      margin: 1.5rem 0 0 0;
    }
  }

  .blogpost-title {
    margin: 3rem 1.5rem 0 1.5rem;
  }

    .faq-title {
    margin: 3rem 1.5rem 0 1.5rem;
    text-align: center;
  }

  .blogpost-info-container {
    margin: 1.5rem 1.5rem 0 1.5rem;
  }

  .blogpost-img {
    min-width: 100%;
    border-radius: 0;
  }

  .blogpost-main-text-container {
    margin: 0 1.5rem;
  }

  .blogpost-blockquote {
    padding: 0 0.5rem 0 1rem;
  }
  // end of blog post

  // faq
  .faq-container {
    margin: 0;

  }

    .faq-title {
    margin: 3rem 1.5rem 0 1.5rem;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .faq-main-text-container {
    margin: 0 1.5rem;
  }
  // end of faq

  // forum page
  .forum-input-lg {
    height: 2.65rem;
    font-size: $medium-text;
    padding: 0.65rem 0.8rem;
  }
  // end of forum page

  // services
  .services-tooltip-container {
    .tooltip-inner {
      min-width: unset;
      max-width: 9rem;
    }
  }
  // end of services
}

@media screen and (max-width: 513px) {
  .account-details-title-container {
    display: inline-block;

    .slide-up-slide-down-long {
      margin-top: -1.65rem;
    }
  }
}

@media screen and (max-width: 478px) {
  // account page
  .account-details-row-container {
    flex-direction: column;
  }
  // end of account page

  // forum page
  .forum-comment-avatar {
    display: none;
  }

  .forum-post-when-posted {
    display: block;
    width: 100%;
    font-weight: $regular;
    margin: -0.25rem 0 0 0;
  }
  // end of forum page

  // contact page
  .contact-container {
    margin: 1.5rem 1rem;
  }

  .contact-header {
    font-size: 1.75rem;
    margin: 0.5rem 0 0 0;
  }

  .contact-subheader {
    font-size: 1.12rem;
    margin: 1.25rem 0 0 0;
  }

  .btn-contact-submit {
    font-size: $small-text;
  }

  .contact-input {
    height: 3rem;
  }

  .contact-input-textarea {
    height: 9.3rem;
  }
  // end of contact page

  // error 404
  .error-404-container {
    margin: 13rem 1.4rem 17rem 1.4rem;
  }

  .error-404-header {
    font-size: 2rem;
  }

  .error-404-body {
    font-size: 1.13rem;
    line-height: 1.8rem;
  }

  .btn-return-home {
    width: 100%;
    padding: 1.2rem 1.35rem;
    margin: 1.4rem;
  }
  // end of error 404

  // home
  .home-copilot-tagline {
    display: none;
  }
  // end of home

  // navbar
  .navbar-hamburger-inner {
    width: 100%;
    right: 0;
    top: 3.5rem;
  }
  // end of navbar

  // onboarding
  .onboarding-container {
    margin: 2.25rem 1.75rem;
    padding: 2rem 1.5rem;
  }

  .onboarding-tooltip-container {
    & .tooltip-inner {
      min-width: unset;
      max-width: 10.5rem;
    }
  }
  // end of onboarding

  // services
  .services-location-modal-container {
    .custom-modal-container {
      padding: 0 1rem;
    }
  }

  .services-location-modal-inner-container {
    padding: 3rem 1.5rem;
  }
  // end of services
}

@media screen and (max-width: 435px) {
  // home
  .home-backed-by-img-container {
    gap: 2.5rem 1.5rem;
  }
  // end of home

  // resources
  .resources-page-container {
    gap: 3.5rem 0;
    margin: 2rem auto;
  }

  .resources-section-container {
    padding: 0 0.5rem;
  }

  .resources-section-title {
    font-size: 1.5rem;
  }

  .resources-section-subtitle,
  .resources-card-title {
    font-size: $medium-text;
  }

  .carousel-cards-container {
    display: flex;
    flex-direction: column;
    gap: 1rem 0;
  }

  .card-img-container,
  .card-img {
    height: unset;
  }

  .resources-card-text-container {
    gap: 0;
    padding: 0.5rem 0;
  }

  .resources-card-read-time {
    font-size: $small-text;
  }

  .resource-page-container {
    padding: 0 0.5rem;
    margin: 1.5rem 0 0 0;
  }

  .resource-link {
    font-size: 1rem;
  }

  .resource-title {
    font-size: 1.5rem;
    font-weight: $bold;
    margin: 1.5rem 0 0 0;
  }

  .resource-description {
    font-size: 1rem;
  }

  .resource-hr {
    margin: 1.5rem 0;
  }

  .scenario-section-container {
    flex-direction: column;
    padding: 0 1rem;
  }

  .scenario-container,
  .scenario-section-script-container {
    padding: 1rem;
  }

  .scenario-main-content-container {
    gap: 1.5rem 0;
  }

  .instructional-videos-info-text {
    font-size: $small-text;
  }

  .blogpost-container {
    .explore-more-cards-section {
      // cannot be combined with class below because blog does not need negative margin
      padding: 2.5rem 0.5rem 3.5rem 0.5rem;
    }
  }

    .faq-container {
    .explore-more-cards-section {
      // cannot be combined with class below because blog does not need negative margin
      padding: 2.5rem 0.5rem 3.5rem 0.5rem;
    }
  }

  .explore-more-cards-section {
    padding: 2.5rem 0.5rem 3.5rem 0.5rem;
    margin: 1.5rem -0.5rem 0 -0.5rem;

    .card-container {
      min-width: 100%;
    }
  }

  .resrouces-author-description {
    font-size: $medium-text;
  }

  .resources-author-container {
    padding: 3rem 0 2rem 0;
  }
  // end of resources
}

@media screen and (max-width: 410px) {
  // account
  .account-checkbox-option,
  .account-radio-other-option {
    flex-wrap: wrap;
  }

  .account-radio-text-input {
    min-width: 100%;
    margin: 0;
  }
  // end of account

  // home
  // Force name height to be 3 lines
  .home-copilot-name {
    min-height: 5.4rem;
  }

  // services
  .services-checkbox-option {
    flex-wrap: nowrap;
  }
  // end of services
}

@media screen and (max-width: 390px) {
  // account page
  .account-profile-photo-saved-container-mobile {
    display: unset;
  }

  .account-profile-photo-saved-container {
    display: none;
  }

  .account-tooltip-container {
    & .tooltip-inner {
      min-width: unset;
      width: 11rem;
    }
  }
  // end of account page

  // forum
  .forum-image-uploading-spinner-container,
  .forum-image-uploaded-edit-container {
    font-size: $small-text;
  }

  .forum-image-uploaded-edit-container {
    min-width: unset;
  }
  // end of forum

  // home
  .home-backed-by-img-container {
    gap: 2.5rem 8rem;
  }
  // end of home
}

@media screen and (max-width: 380px) {
  // forum
  .forum-comment-actions-container {
    margin: 0.5rem 0 0 0;

    .forum-uploaded-img {
      margin: 0 auto 0.5rem auto;
    }
  }
  // end of forum

  // onboarding
  .onboarding-tooltip-container {
    & .tooltip-inner {
      min-width: unset;
      max-width: 9rem;
    }
  }
  // end of onboarding

  // navbar
  .navbar-container {
    padding: 0 1rem;
  }
  // end of navbar
}

@media screen and (max-width: 360px) {
  .error-404-container {
    margin: 10.75rem 1.4rem 15.5rem 1.4rem;
  }
}

@media screen and (max-width: 359px) {
  // forum page
  .forum-title-error {
    margin: 0.19rem 0 0 0;
  }
  // end of forum page
}

@media screen and (max-width: 340px) {
}

// ensure display:none always overrides any other display property where used
.hide {
  display: none;
}
