// this keeps the footer at the bottom of the screen no matter the content height
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

// Below moves content down so that fixed header can be displayed without obscuring content
.content-container {
  width: 100%;
  margin: 4.5rem auto 0 auto;
}
