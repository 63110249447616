.dropdown-option {
  position: relative;
  font-family: $main-font;
  color: $color-text-secondary;
  font-weight: $regular;
  height: 2.95rem;
  border-bottom: 1px solid $color-accent;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;

  &:hover {
    background-color: $color-secondary-5;
  }
}

.forum-sidebar-dropdown {
  position: relative;
  display: none;
  width: 100%;
  border: 1px solid $color-accent;
  border-radius: 3px;
}

.forum-sidebar-dropdown-selected {
  position: relative;
  display: flex;
  align-items: center;
  color: $color-text-primary;
  height: 3.3rem;
  padding: 0.75rem 0.75rem 0.75rem 1rem;
  cursor: pointer;
}

.forum-sidebar-dropdown-text {
  width: 100%;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.sidebar-dropdown-option-selected {
  font-weight: $bold;
  color: $color-primary;
}

.forum-dropdown-filter-tag {
  font-weight: $regular;
}

.forum-sidebar-caret-down {
  font-size: 1.5rem;
  margin-top: -0.6rem;
  margin-left: auto;
}

.forum-sidebar-caret-up {
  font-size: 1.5rem;
  transform: rotate(180deg);
  margin-top: 0.6rem;
  margin-left: auto;
}

.forum-sidebar-dropdown-options {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  background-color: $white;
  width: 100%;
  border: none;
  box-shadow: $main-shadow;
  z-index: 79;
  margin: 0.5rem 0 0 0;
  padding: 1rem;
}

.forum-sidebar-dropdown-option {
  display: flex;
  align-items: center;
  color: $color-text-primary;
  font-size: 1.12rem;
  border: none;
  padding: 0.5rem 0.75rem;
  cursor: pointer;

  &:hover {
    color: $color-primary;
    background-color: $white;

    .forum-filter-tag-icon {
      background-image: url("https://carecopilot-static-assets.s3.amazonaws.com/tag-hover.png");
    }
  }
}

.sidebar-dropdown-filter-x {
  color: $color-primary;
  width: 1.12rem;
  text-align: center;
  margin-right: 0.5rem;
}

.dropdown {
  position: relative;
  font-family: $main-font;
  font-size: $small-text;
  border: 1px solid transparent;
  border-radius: 5px;
  cursor: pointer;

  .rw-widget-input {
    background-color: $white;
    height: 3rem;
    border-color: $color-accent;
    font-weight: $bold;
    box-shadow: none !important;
    user-select: none;
  }

  .rw-state-focus {
    .rw-widget-picker {
      border-color: $color-accent;
    }
  }

  .rw-list,
  .rw-popup {
    background-color: $white;
  }

  .rw-dropdownlist-search {
    font-family: $main-font;
    font-weight: $regular;
  }

  .rw-dropdown-list-value {
    font-size: 1.12rem;
    color: $color-text-primary;
    background-color: $white;
  }

  .rw-picker-caret {
    position: relative;
    font-size: 1.75rem;
    color: $color-text-primary;
    right: 0.5rem;
  }

  .rw-list-option {
    font-size: 1.12rem;
    color: $color-text-primary;
    background-color: $white;
    padding-left: 1rem;
    padding: 0.35rem 1rem;

    &:hover {
      background-color: $color-secondary-5;
    }

    &.rw-state-selected {
      background-color: $color-primary;
      border-color: $color-primary;
      color: $white;
    }
  }

  // Scroll bar
  .rw-list {
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 0.88rem;
    }
     
    &::-webkit-scrollbar-track {
      background-color: $white;
      border: 0.25rem solid $white;
    }
     
    &::-webkit-scrollbar-thumb {
      border: 0.25rem solid $white;
      border-radius: 0.5rem;
    }
  }
}

.account-dropdown {
  margin-top: 0.5rem;

  .rw-widget-input {
    height: 3.12rem;
  }

  .rw-dropdownlist-search {
    color: $black;
    font-size: 1.12rem;
  }

  .rw-dropdown-list-input {
    height: 1.12rem;
  }

  .rw-list-option {
    color: $black;
    font-size: 1rem;
    line-height: 1.6;
  }

  .rw-dropdown-list-value {
    color: $black;
    font-size: 1rem;
    line-height: 1;
  }
}

.dropdown-open {
  border: 1px solid $color-primary;
  border-radius: 0.25rem;
  box-shadow: $input-focus-shadow;

  .rw-widget-input {
    background-color: $white;

    &:hover {
      background-color: $white;
    }
  }

  .rw-dropdown-list-value {
    background-color: $white;
  }
 
  .rw-popup {
    box-shadow: $secondary-shadow;
    border: 0;
    margin-top: 0.25rem;
  }

  .rw-picker-caret {
    transform: rotate(180deg);
  }
}

.dropdown-no-value {
  .rw-widget-input,
  .rw-dropdown-list-value {
    background-color: $color-neutral;
    color: $color-text-secondary;
    
    &:hover {
      background-color: $color-neutral;
    }
  }
}
