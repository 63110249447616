// Colors
$color-primary: #4771DC;
$color-primary-highlight: #234DB7;

$color-secondary-1: #7EA5FC;
$color-secondary-2: #B3CAFD;
$color-secondary-3: #C5D7FE;
$color-secondary-4: #D8E4FE;
$color-secondary-5: #EBF1FF;
$color-secondary-6: #FBFDFF;

$color-tertiary: #FF932E;
$color-tertiary-highlight: #FEE3CB;

$color-text-primary: #51596C;
$color-text-secondary: #979CAA;

$color-accent: #E8E9EC;
$color-danger-primary: #E23C47;
$color-danger-secondary: #D81F1F;
$color-neutral: #F2F4F8;

$white: #FAFCFE;
$black: #161920;


// Font weights
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;


// Font sizes
$small-text: 0.88rem;
$medium-text: 1rem;


// Font families
@font-face {
  font-family: 'Pangram';
  src: url("../../fonts/Pangram-Regular.otf");
  font-display: swap;
}

@font-face {
  font-family: "Pangram";
  src: url("../../fonts/Pangram-Medium.otf");
  font-weight: $medium;
  font-display: swap;
}

@font-face {
  font-family: "Pangram";
  src: url("../../fonts/Pangram-Semibold.otf");
  font-weight: $semibold;
  font-display: swap;
}

@font-face {
  font-family: "Pangram";
  src: url("../../fonts/Pangram-Bold.otf");
  font-weight: $bold;
  font-display: swap;
}
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&family=Roboto&display=swap');

$main-font: "Pangram", sans-serif;
$heading-font: "Lora", serif;

// Transitions
$main-transition: all 200ms ease;
$long-transition: all 300ms ease;
$longer-transition: all 400ms ease;


// Box shadow
$main-shadow: 0 0.25rem 1.25rem rgba(143, 143, 143, 0.2);
$secondary-shadow: 0px 0px 10px rgba(143, 143, 143, 0.2);
$tertiary-shadow: inset 0px 2px 4px -1px rgba(22, 25, 32, 0.05);
$input-focus-shadow: 0px 0px 0px 2px rgba(71, 113, 220, 0.2);
$error-shadow: 0px 0px 0px 2px rgba(253, 121, 161, 0.2);
$icon-shadow: 0px 2px 5px rgba(22, 25, 32, 0.2);
