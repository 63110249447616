// The carousel does not work with 'gap', so as a workaround, show 2rem gap between slides
// by using the 'left' property combined with 'margin-left: -2rem;' on the parent container
.carousel__inner-slide {
  width: calc(100% - 2rem) !important;
  left: 2rem !important;
}

.carousel-slider-container {
  margin-left: -2rem;
}

.carousel-slider {
  min-height: 27rem !important;
}

.carousel-arrow {
  position: absolute;
  font-size: 1.5rem;
  color: $white;
  background-color: $color-primary;
  width: 2.8rem;
  height: 2.8rem;
  border: 3px solid $white;
  border-radius: 50%;

  &:nth-of-type(1) {
    left: -1.3rem;
  }

  &:nth-of-type(2) {
    right: -1.3rem;
  }

  // Pure-react-carousel disables the button if there are no
  // slides to navigate to; in this case, hide the button
  &:disabled {
    display: none;
  }
}
