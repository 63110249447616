// forum mobile tag modal
@keyframes slide-into-view {
  0% { transform: translateY(100%); }
  100% { transform: translateY(0%); }
}

@-webkit-keyframes slide-into-view {
  0% { transform: translateY(100%); }
  100% { transform: translateY(0%); }
}

@keyframes slide-out {
  0% { transform: translateY(0%); }
  100% { transform: translateY(100%); }
}

@-webkit-keyframes slide-out {
  0% { transform: translateY(0%); }
  100% { transform: translateY(100%); }
}

// error 404 page
.slide-up-fast {
  animation: slide-up 0.5s forwards;
  -webkit-animation: slide-up 0.5s forwards;
}

@keyframes slide-up {
  0% { 
    transform: translateY(30%);
    opacity: 0;
  }
  100% { 
    transform: translateY(0%); 
    opacity: 1;
  }
}

@-webkit-keyframes slide-up {
  0% { 
    transform: translateY(30%);
    opacity: 0;
  }
  100% { 
    transform: translateY(0%); 
    opacity: 1;
  }
}

.slide-up-slide-down {
  position: relative;
  animation: slide-up-slide-down 5s forwards;
  -webkit-animation: slide-up-slide-down 5s forwards;
}

@keyframes slide-up-slide-down {
  0% {
    // z-index 97 ensures this displays above the "Back to top" button
    z-index: 97;
    transform: translateY(30%);
    opacity: 0;
  }
  9% { 
    z-index: 97;
    transform: translateY(0%);
    opacity: 1;
  }
  91% { 
    z-index: 97;
    transform: translateY(0%);
    opacity: 1;
  }
  100% { 
    z-index: 97;
    transform: translateY(20%);
    opacity: 0;
  }
}

@-webkit-keyframes slide-up-slide-down {
  0% {
    z-index: 97;
    transform: translateY(30%);
    opacity: 0;
  }
  9% { 
    z-index: 97;
    transform: translateY(0%);
    opacity: 1;
  }
  91% { 
    z-index: 97;
    transform: translateY(0%);
    opacity: 1;
  }
  100% { 
    z-index: 97;
    transform: translateY(20%);
    opacity: 0;
  }
}

// unlike .slide-up-slide-down, the element this is applied to doesn't have translateX(-50%)
.slide-up-slide-down-long {
  display: inline-block;
  height: 1.63rem;
  margin-top: -1.65rem; // counteract the line-height from the parent's display: inline-block
  animation: slide-up-slide-down-long 4s forwards;
  -webkit-animation: slide-up-slide-down-long 4s forwards;
}

@keyframes slide-up-slide-down-long {
  0% { 
    transform: translateY(30%);
    opacity: 0;
    min-width: 5.25rem;
  }
  15% { 
    transform: translateY(0%);
    opacity: 1;
    min-width: 5.25rem;
  }
  85% { 
    transform: translateY(0%);
    opacity: 1;
    min-width: 5.25rem;
  }
  99% { 
    transform: translateY(20%);
    opacity: 0;
    min-width: 5.25rem;
  }
  100% {
    transform: translateY(20%);
    opacity: 0;
    width: 0;
    min-width: 0;
  }
}

@-webkit-keyframes slide-up-slide-down-long {
  0% { 
    transform: translateY(30%);
    opacity: 0;
    min-width: 5.25rem;
  }
  15% { 
    transform: translateY(0%);
    opacity: 1;
    min-width: 5.25rem;
  }
  85% { 
    transform: translateY(0%);
    opacity: 1;
    min-width: 5.25rem;
  }
  99% { 
    transform: translateY(20%);
    opacity: 0;
    min-width: 5.25rem;
  }
  100% {
    transform: translateY(20%);
    opacity: 0;
    width: 0;
    min-width: 0;
  }
}

@keyframes slide-up-modal {
  0% { 
    opacity: 0.3;
    transform: translateY(100%);
  }
  100% { 
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes slide-up-modal {
  0% { 
    opacity: 0.3;
    transform: translateY(100%);
  }
  100% { 
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-down-modal {
  0% { 
    opacity: 1;
    transform: translateY(0);
  }
  100% { 
    opacity: 0;
    transform: translateY(100%);
  }
}

@-webkit-keyframes slide-down-modal {
  0% { 
    opacity: 1;
    transform: translateY(0);
  }
  100% { 
    opacity: 0;
    transform: translateY(100%);
  }
}

@keyframes fade-in-modal {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes fade-in-modal {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fade-out-modal {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@-webkit-keyframes fade-out-modal {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

// onboarding screens
.swipe-left {
  animation: swipe-left 650ms;
  -webkit-animation: swipe-left 650ms;
}

@keyframes swipe-left {
  0% {
    transform: translateX(0%);
  }
  49% { 
    transform: translate(-100%);
    opacity: 0;
  }
  50% {
    transform: translate(100%);
    opacity: 0;
  }
  51% { 
    transform: translate(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes swipe-left {
  0% {
    transform: translateX(0%);
  }
  49% { 
    transform: translate(-100%);
    opacity: 0;
  }
  50% {
    transform: translate(100%);
    opacity: 0;
  }
  51% { 
    transform: translate(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
  }
}

.swipe-right {
  animation: swipe-right 650ms;
  -webkit-animation: swipe-right 650ms;
}

@keyframes swipe-right {
  0% {
    transform: translateX(0%);
  }
  49% { 
    transform: translate(100%);
    opacity: 0;
  }
  50% {
    transform: translate(-100%);
    opacity: 0;
  }
  51% { 
    transform: translate(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes swipe-right {
  0% {
    transform: translateX(0%);
  }
  49% { 
    transform: translate(100%);
    opacity: 0;
  }
  50% {
    transform: translate(-100%);
    opacity: 0;
  }
  51% { 
    transform: translate(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
  }
}

// Account completion donut
@keyframes show-percentage {
  to { stroke-dasharray: 0 100; }
}

@-webkit-keyframes show-percentage {
  to { stroke-dasharray: 0 100; }
}
