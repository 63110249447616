.error-404-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $color-text-primary;
  margin: 12.75rem auto 15.5rem auto;
  text-align: center;
  max-width: 31rem;
  opacity: 0;
}

.error-404-body {
  margin: 1rem 0;
}