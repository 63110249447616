.error-text {
  font-family: $main-font;
  font-size: $small-text;
  color: $color-danger-primary;
  margin-top: 0.5rem;
}

.forum-error-contact-link {
  color: $color-primary;

  &:hover {
    color: $color-primary-highlight;
  }
}

.forum-image-upload-error-container {
  display: flex;
  min-width: fit-content;
  gap: 0 0.85rem;
  margin-left: 0.3rem;
}

.forum-image-upload-error {
  font-size: 1rem;
  margin: 0;
}

.forum-title-error {
  font-size: 1rem;
  margin: 0;
}

.forum-posting-error {
  font-size: $medium-text;
  line-height: 1.25;
  text-align: right;
}

.forum-post-report-error {
  font-size: $medium-text;
  margin: 0;
}

.forum-comment-report-error {
  font-size: $medium-text;
  margin: 0 0 0 auto;
}

.contact-incomplete-email-error {
  font-size: 1rem;
  color: $color-text-primary;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: -0.5rem;
}

.contact-submission-error {
  background-color: $color-tertiary-highlight;
  margin-top: 0.75rem;
  padding: 1rem;
}

.input-error {
  border-color: $color-danger-primary;
  box-shadow: $error-shadow;

  &:focus-within {
    border-color: $color-danger-primary;
    box-shadow: $error-shadow;
  }
}

.dropdown-error {
  .rw-widget-input {
    border: none;
    box-shadow: none;
  }

  .rw-widget {
    border: 1px solid $color-danger-primary;
    box-shadow: $error-shadow;
    border-radius: 0.2rem;
  }
}

.account-input-message {
  font-size: 1rem;
  color: $color-text-primary;
  margin-top: 0.5rem;
}
