.resources-page-container {
  display: flex;
  flex-direction: column;
  gap: 4.5rem 0;
  max-width: 80rem;
  margin: 3.5rem auto;
}

.resources-section-container {
  padding: 0 2rem;
}

.resources-faq-section {
  padding: 0 2.5rem;
}

.resources-faq-container {
  gap: 1.5rem;
}

.resources-section-subtitle {
  margin: 1rem 0 2rem 0;
}

.card-container {
  &:hover {
    .resources-card-title {
      font-weight: $bold;
      text-decoration: underline;
    }
  }
}

.resources-card-container {
  &:hover {
    .card-chat-overlay {
      &::after {
        color: $color-secondary-2;
      }
    }

    .card-video-overlay {
      &::after {
        color: $color-primary;
      }
    }
  }
}

// Styles shared by multiple types of resource
.resource-page-container {
  max-width: 58rem;
  padding: 0 1rem;
  margin: 2.5rem auto;
}

.resource-link {
  display: flex;
  align-items: center;
  gap: 0 0.5rem;
  font-weight: $bold;
  color: $color-primary;
  transition: $main-transition;
  -webkit-transition: $main-transition;

  &:hover {
    color: $color-primary-highlight;
    transition: $main-transition;
    -webkit-transition: $main-transition;
  }
}

.resource-link-chevron {
  font-size: 0.7rem;
}

.resource-title {
  margin-top: 2.5rem;
}

.resource-description {
  font-size: 1.38rem;
  margin: 1rem 0;
}

.resource-hr {
  border-bottom: 1px solid $color-accent;
  margin: 2rem 0;
}

// Conversation scripts page
.conversation-scripts-img {
  width: 100%;
  border-radius: 0.63rem;
}

.conversation-scripts-container {
  display: flex;
  flex-direction: column;
  gap: 3.5rem 0;
  margin-top: 2rem;
}

.scenario-container {
  border-radius: 0.63rem;
  box-shadow: $secondary-shadow;
  padding: 2rem 1.5rem;
}

.scenario-main-content-container {
  display: flex;
  flex-direction: column;
  gap: 2rem 0;
}

.scenario-section-container {
  display: flex;
  gap: 0.5rem 2rem;
  border-radius: 0.63rem;
  padding: 0 1rem;
}

.scenario-section-script-container {
  background-color: rgba(235, 241, 255, 0.5);
  padding: 1rem;
}

.scenario-section-title {
  color: $color-primary;
  min-width: 5rem;
  line-height: 1.7;
}

.scenario-section-description {
  line-height: 1.7;
}

.scenario-section-list {
  padding: 0 0 0 1.5rem;

  li {
    padding: 0 0 0 0.5rem;
  }
}

.conversation-scripts-request {
  margin-top: 3.5rem;
}

.resources-link {
  color: $black;
  text-decoration: underline;
  
  &:link,
  &:hover,
  &:active,
  &:visited {
    color: $black;
    text-decoration: underline;
  }
}

// Instructional videos page
.instructional-videos-info-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.instructional-videos-icon {
  height: 1.38rem;
}

// Author bio section
.resources-author-container {
  display: flex;
  gap: 1rem 3rem;
  border-top: 1px solid $color-accent;
  margin-top: 3.5rem;
  padding-top: 3.5rem;
}

.resources-author-img {
  object-fit: cover;
  width: 7.25rem;
  height: 7.25rem;
  border-radius: 50%;
  border: 3px solid $white;
  box-shadow: 0px 0px 10px 2px rgba(69, 69, 69, 0.2);
}

.resources-author-text-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.resources-author-name {
  font-size: 1.5rem;
  font-weight: $bold;
}

.resources-author-tagline {
  color: $color-primary;
}

.resources-author-btn-container {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}
