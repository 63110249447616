.contact-page-container {
  flex: 1;
}

.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $black;
  width: 52.5rem;
  margin: 2rem auto;
}

.contact-header {
  text-align: center;
}

.contact-subheader {
  text-align: center;
  margin: 1.25rem 0;
}

.contact-form {
  width: 100%;
}

.contact-row-container {
  display: flex;
  justify-content: space-between;
}

.contact-input-container {
  display: flex;
  flex-direction: column;
}

.contact-input-label {
  width: 100%;
  text-align: left;
  padding-left: 0.5rem;
  margin-top: 2.5rem;
}

.contact-input {
  color: $color-text-primary;
  background-color: $white;
  height: 3.9rem;
  width: 25.7rem;
  border: 1px solid $color-accent;
  border-radius: 6px;
  outline: none;
  padding: 1.2rem 1.5rem;
  margin-top: 0.3rem;

  &::placeholder {
    color: $color-text-secondary;
    opacity: 0.75;
  }

  &:active,
  &:focus {
    border-color: $color-primary;
    box-shadow: $input-focus-shadow;
  }
}

.contact-input-textarea {
  width: 100%;
  height: 9.3rem;
  line-height: 1.6rem;
}

.contact-submission-success {
  background-color: $color-accent;
  font-size: 1.05rem;
  padding: 1.3rem;
  width: 100%;
  text-align: center;
  margin-top: 1.5rem;
}
