.unsubscribe-container {
  padding: 25vh 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
}

.unsubscribe-text {
  margin-top: 1.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  & span {
    margin: 0.25rem;
  }
}
