.carecopilots-page-container {
  max-width: 93rem;
  padding: 7rem 6.25rem 9rem 6.25rem;
  margin: 0 auto;
}

.carecopilots-title {
  font-family: $heading-font;
  margin-bottom: 1rem;
}

.carecopilots-container {
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  margin-top: 5rem;
}

.carecopilot-container {
  display: flex;
  gap: 1.5rem 4rem;
  background-color: $color-secondary-5;
  border-radius: 0.63rem;
  padding: 2.5rem;
}

.carecopilot-img {
  width: 15.25rem;
  height: 15.25rem;
  border: 3px solid $color-secondary-6;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 2px rgba(69, 69, 69, 0.2);
}

.carecopilot-text-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.carecopilot-name {
  font-family: $heading-font;
  font-size: 2.25rem;
  font-weight: $medium;
}

.carecopilot-role {
  font-size: 1.25rem;
  font-weight: $medium;
  text-transform: uppercase;
}

.carecopilot-description {
  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }
}
