.cards-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  max-width: 80rem;
}

.card-container {
  position: relative;
  color: $black;
  border-radius: 0.63rem;
  top: 0;
  overflow: hidden;
  cursor: pointer;
  transition: $main-transition;
  -webkit-transition: $main-transition;
  
  &:hover {
    color: $black;
    transition: $main-transition;
    -webkit-transition: $main-transition;

    .card-title {
      color: $color-primary-highlight;
    }
  }
}

.card-overlay {
  &::after {
    font-family: "Font Awesome 6 Free";
    position: absolute;
    color: $white;
    top: 0;
    left: 0;
    transition: $main-transition;
    -webkit-transition: $main-transition;
  }
}

.card-chat-overlay {
  &::after {
    content: "\f086";
    font-size: 2rem;
    background-color: $black;
    width: 8.1rem;
    height: 7.1rem;
    border-top-left-radius: 0.63rem;
    opacity: 0.8;
    clip-path: polygon(0 0, 100% 0, 0 100%);
    padding-top: 0.5rem;
    padding-left: 1rem;
  }
}

.card-video-overlay {
  &::after {
    content: "\f144";
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.5rem;
    width: 100%;
    height: 100%;
  }
}

.card-img-container {
  position: relative;
}

.card-img {
  height: 100%;
  max-height: 13.1rem;
  width: 100%;
  object-fit: cover;
  object-position: 50% 30%;
  border-radius: 0.63rem;
}

.card-text-container {
  display: flex;
  flex-direction: column;
  background-color: $white;
  gap: 0.5rem 0;
  padding: 1.5rem 0;
}

.card-info-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0 0.5rem;
  align-items: center;
  color: $black;
}

.card-title {
  color: $black;
  transition: $main-transition;
  -webkit-transition: $main-transition;
  margin: 0;
}

// Explore more cards
.explore-more-cards-section {
  padding-top: 2.5rem;
  margin-top: 1.5rem;
}

.explore-more-cards-title {
  text-align: center;
  margin-bottom: 2rem;
}

.explore-more-cards-container {
  justify-content: center;
  gap: 1rem;

  .card-container {
    // width is 1/3 of available space minus 1/3 of the total gap
    width: calc(33.3% - 2rem / 3);
    min-width: 18rem;
    max-width: calc(50% - 0.5rem);
  }
}
