.header-lg {
  font-family: $main-font;
  font-weight: $medium;
  font-size: 3.25rem;
  letter-spacing: -0.02rem;
}

.header-md {
  font-family: $main-font;
  font-weight: $medium;
  font-size: 2.5rem;
  letter-spacing: -0.02rem;
}

.header-sm {
  font-family: $main-font;
  font-weight: $bold;
  font-size: 1.5rem;
  line-height: 2.1rem;
}

.text-lg {
  font-family: $main-font;
  font-weight: $regular;
  font-size: 1.5rem;
}

.text-md {
  font-family: $main-font;
  font-weight: $regular;
  font-size: 1.12rem;
  line-height: 1.4;
}

.text-md-bold {
  font-family: $main-font;
  font-weight: $bold;
  font-size: 1.12rem;
  line-height: 1.4;
}

.text-sm {
  font-family: $main-font;
  font-weight: $regular;
  font-size: 1rem;
}

.text-sm-bold {
  font-family: $main-font;
  font-weight: $bold;
  font-size: 1rem;
}

.text-xs-uppercase {
  font-family: $main-font;
  font-weight: $regular;
  font-size: 0.88rem;
  letter-spacing: 0.03rem;
  text-transform: uppercase;  
}

.text-xs {
  font-family: $main-font;
  font-weight: $regular;
  font-size: 0.88rem;
}

.text-link {
  font-family: $main-font;
  font-weight: $bold;
  font-size: 1.12rem;
  text-decoration: underline;
  cursor: pointer;

  &:link,
  &:visited,
  &:hover {
    text-decoration: underline;
  }
}
