$home-width: 112rem;

// Hero section
.home-hero-section {
  display: flex;
  align-items: center;
  background-image: url("https://carecopilot-static-assets.s3.amazonaws.com/home-hero.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right calc(5rem + 40%) bottom 0;
  height: 43rem;
  padding: 4.75rem 6.25rem 5.75rem 6.25rem;
  margin: 0 auto;
}

.home-hero-text-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  max-width: $home-width;
  margin: 0 auto;
}

.home-hero-title {
  font-family: $heading-font;
  max-width: 50rem;
}

.home-hero-subtitle {
  font-size: 1.5rem;
  max-width: 50rem;
}

.home-hero-link {
  display: block;
  color: $black;
  margin-top: 1rem;

  &:hover {
    color: $black;
  }
}

// Advice section
.home-advice-section {
  background-color: $color-secondary-5;
  padding: 7rem 6.25rem 9rem 6.25rem;
}

.home-advice-container {
  max-width: $home-width;
  margin: 0 auto;
}

.home-advice-subtitle {
  font-size: 1.37rem;
}

.home-advice-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  margin-top: 0.5rem;
}

.home-advice-title-link {
  font-weight: $regular;
  color: $black;
  min-width: fit-content;

  &:link,
  &:visited,
  &:hover {
    color: $black;
  }
}

.home-advice-title {
  font-family: $heading-font;
}

.home-advice-cards-container {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  margin-top: 5rem;
}

.home-card-container {
  display: flex;
  gap: 1rem 3.5rem;
}

.home-card-img-container {
  position: relative;
  width: calc(50% - 1.75rem);
}

.home-card-img {
  width: 100%;
  object-fit: cover;
  object-position: top;
  aspect-ratio: 2;
  border-radius: 0.63rem;
  box-shadow: $secondary-shadow;
}

.home-card-img-tag {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.12rem;
  font-weight: $bold;
  text-transform: uppercase;
  background-color: $color-primary;
  color: $color-secondary-6;
  border-radius: 0.44rem;
  box-shadow: $icon-shadow;
  top: 1rem;
  left: 1rem;
  padding: 0.2rem 0.5rem;
}

.home-card-text-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: calc(50% - 1.75rem);
}

.home-card-author-container {
  display: flex;
  align-items: center;
  gap: 0.5rem
}

.home-card-icon {
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 50%;
}

.home-card-author-text-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0 0.5rem;
}

.home-card-description {
  // Limit text to 4 lines
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow-y: hidden;
  text-overflow: ellipsis;
}

.home-link {
  color: $black;

  &:hover {
    color: $black;
  }
}

// Copilots section
.home-copilots-section {
  background-image: url("https://carecopilot-static-assets.s3.amazonaws.com/home-circle-bg.png");
  background-color: $color-primary-highlight;
  background-repeat: no-repeat;
  background-position: calc(50% + 12rem) 40%;
  padding: 7rem 6.25rem 8.5rem 6.25rem;
}

.home-copilots-content-container {
  display: flex;
  align-items: center;
  gap: 5.5rem;
  max-width: $home-width;
  margin: 0 auto;
}

.home-copilots-text-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  color: $color-secondary-6;
  width: 50%;
}

.home-copilots-title {
  font-family: $heading-font;
}

.home-copilots-subtitle {
  font-size: 1.5rem;
}

.home-copilots-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  width: 50%;
}

.home-copilot-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem;
  color: $white;
  width: 100%;
}

.home-copilot-img {
  width: 12.5rem;
  height: 12.5rem;
  border: 3px solid $color-secondary-6;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 2px rgba(69, 69, 69, 0.2);
  margin-bottom: 0.5rem;
}

.home-copilot-name {
  font-size: 1.5rem;
  font-weight: $bold;
  text-transform: uppercase;
}

.home-copilot-tagline {
  white-space: pre-wrap;
  margin-top: auto;
}

// Practical help section
.home-practical-help-section {
  display: flex;
  flex-direction: column;
  gap: 4.5rem;
  // Include padding in max-width calculation
  max-width: calc($home-width + 12.5rem);
  margin: 0 auto;
  padding: 7rem 6.25rem 8.5rem 6.25rem;
}

.home-practical-help-text-containers {
  display: flex;
  gap: 3rem 6.25rem;
}

.home-practical-help-title-container {
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  // width is 50% minus 50% of the gap
  width: calc(50% + 3.125rem);
}

.home-practical-help-title {
  font-family: $heading-font;
}

.home-practical-help-list-container {
  background-color: $color-secondary-5;
  width: calc(50% + 3.125rem);
  border-radius: 0.625rem;
  padding: 3.5rem;
}

.home-practical-help-list {
  padding: 0;

  li {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    font-size: 1.5rem;
    list-style-type: none;
    margin: 1.5rem 0 0 1rem;
  }
}

.home-practical-help-tick {
  font-size: 2rem;
  color: $color-tertiary-highlight;
  background-color: $color-tertiary;
  border-radius: 50%;
}

.home-practical-help-video {
  width: 100%;
  max-width: 75rem;
  aspect-ratio: 16 / 9;
  border-radius: 0.63rem;
  margin: 0 auto;
}

// Mission section
.home-mission-section {
  //background-color: $color-primary;
  //color: $color-secondary-6;
  //border-top: 2.25rem solid $color-tertiary;
  padding: 7.5rem 6.25rem 8.5rem 6.25rem;
}

.home-mission-content-container {
  display: flex;
  justify-content: center;
  gap: 6.25rem;
  max-width: $home-width;
  margin: 0 auto;
}

.home-mission-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  width: 50%;
}

.home-mission-title {
  font-family: $heading-font;
  margin-bottom: 1rem;
}

.home-mission-link {
  color: $color-secondary-6;
  text-decoration: underline;

  &:link,
  &:visited,
  &:hover {
    color: $color-secondary-6;
    text-decoration: underline;
  }
}

.home-mission-video-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.home-mission-video-inner-container {
  position: relative;
  width: 100%;
  // use padding to keep 16:9 aspect ratio
  padding-bottom: 56.25%;
}

.home-mission-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.63rem;
}

// Backed by section
.home-backed-by-container {
  display: flex;
  flex-direction: column;
  gap: 6rem;
  align-items: center;
  background: linear-gradient(0deg, $color-secondary-5 0%, $color-secondary-6 25%, $color-secondary-6 100%);
  width: 100%;
  padding: 5rem 6.25rem;
  margin: auto;
}

.home-backed-by-title {
  font-family: $heading-font;
}

.home-backed-by-img-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 5.5rem 10rem;
  max-width: 90rem;
}

.img-techstars {
  width: 21rem;
}

.img-fearless-fund {
  width: 12.5rem;
}

.img-aarp {
  width: 10rem;
}

.img-google-for-startups {
  width: 15.5rem;
}

.img-visible-hands {
  width: 19rem;
}

.img-aws {
  width: 9rem;
}

.home-backed-by-arch {
  width: 100%;
  border-top: 1px solid $color-secondary-5;
}

// Signup section
.home-signup-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin: 6.5rem auto;
}

.home-signup-text {
  font-family: $heading-font;
}
