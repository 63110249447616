.btn-reset {
  font-family: $main-font;
  border-radius: 100vmax;
  font-size: 1rem;
  font-weight: $medium;
  text-align: center;
  text-transform: none;
  border: none;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  transition: $main-transition;
  -webkit-transition: $main-transition;
  cursor: pointer;

  &:hover,
  &:active {
    transition: $main-transition;
    -webkit-transition: $main-transition;
  }
}

.btn-main {
  color: $white !important;
  background-color: $color-primary !important;
  border: 1px solid $color-primary;
  padding: 0.7rem 2rem;

  &:hover,
  &:active {
    color: $white;
    background-color: $color-primary-highlight !important;
  }
}

// this can't be named btn-secondary because bootstrap uses this name
.btn-alternate {
  font-family: $main-font;
  color: $black;
  background-color: $white;
  border: 1px solid $black;
  padding: 0.7rem 2rem;

  &:hover,
  &:active {
    color: $black;
    background-color: $color-secondary-5;
    box-shadow: $icon-shadow;
  }
}

.btn-tertiary {
  color: $color-secondary-6;
  background-color: $color-tertiary;
  border: 1px solid $color-tertiary;
  padding: 0.7rem 2rem;

  &:link,
  &:visited {
    color: $color-secondary-6;
    background-color: $color-tertiary;
    border: 1px solid $color-tertiary;
  }

  &:visited:hover,
  &:hover,
  &:active {
    color: $white;
    background-color: $color-primary;
    border: 1px solid $color-primary;
  }
}

.btn-hamburger {
  font-size: 1.12rem;
  font-weight: $regular;
  color: $black;
  background-color: $white;
  outline: none;
  border: none;
  border-bottom: 1px solid $color-accent;
  border-radius: 0;
  text-align: left;
  padding: 0.65rem 1.5rem;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;

  &:hover {
    color: $white;
    background-color: $color-primary;
  }

  > i {
    margin-right: 0.5rem;
  }
}

.btn-contact-submit {
  font-size: 1.12rem;
  width: 100%;
  padding: 1.1rem;
  margin: 2.1rem 0 1.6rem 0;
}

.btn-return-home {
  font-size: 1.12rem;
  font-weight: $regular;
  padding: 0.75rem 1.5rem;
  margin: 1.2rem auto;
}

.btn-default-modal {
  width: 12.5rem;
  height: 2.5rem;
  padding: 0;
}

.btn-forum-new-post {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 100%;
  margin-top: 2rem;
  padding: 0;
}

.btn-forum-navigation {
  flex: 1;
  font-weight: $regular;
  font-size: 1.12rem;
  color: $black;
  background-color: $color-neutral;
  height: 100%;
  border-radius: 0.63rem;
  transition: none;

  &:hover {
    font-weight: $bold;
    transition: none;
  }
}

.btn-forum-navigation-current {
  font-weight: $bold;
  background-color: $white;
  box-shadow: $secondary-shadow;
}

.btn-forum-comment {
  padding: 0.5rem 2rem;
}

.btn-forum-comment-update {
  color: $color-text-primary;
  background-color: transparent;

  &:hover {
    color: $color-primary;
  }
}

.btn-multi-select {
  font-weight: $regular;
  background-color: $color-neutral;
  color: $color-text-primary;
  padding: 0.38rem 1rem;

  &:hover {
    background-color: $color-secondary-3;
    color: $color-text-primary;
  }

  &.btn-multi-select-selected {
    color: $white;
    background-color: $color-primary;
  }
}

.btn-multi-select-services {
  font-weight: $medium;
  background-color: $white;
  color: $color-text-primary;
  padding: 0.4rem 1rem;

  &:hover {
    background-color: $color-secondary-3;
    color: $color-text-primary;
  }

  &.btn-multi-select-selected {
    background-color: $color-secondary-3;
    color: $color-text-primary;
  }
}

.btn-multi-select-icon {
  font-size: 0.85rem;
  margin-left: 0.5rem;
}

.btn-scroll-top {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 1rem;
  right: 1rem;
  background-color: $color-secondary-2;
  height: 2.9rem;
  width: 2.9rem;
  border-radius: 50%;
  cursor: pointer;
  transition: $longer-transition;
  -webkit-transition: $longer-transition;
  z-index: 96;

  &:hover {
    transform: scale(1.1);
    opacity: 0.6;
    transition: $longer-transition;
    -webkit-transition: $longer-transition;
  }
}

.btn-scroll-top-caret {
  font-size: 1.3rem;
  color: $white;
}

.btn-image-upload-error {
  display: flex;
  align-items: center;
  justify-content: right;
  font-size: 1.12rem;
  background-color: transparent;
  color: $black;
  font-weight: $bold;
  min-width: fit-content;
  height: 1.5rem;
  border-radius: 0;
  padding: 0;

  &:hover {
    color: $color-primary;
  }
}

.btn-forum-load-more {
  color: $color-primary;
  background-color: transparent;
  font-weight: $regular;
  font-size: 1.15rem;
  cursor: pointer;
  text-align: center;
  margin: 1rem 0;

  &:hover {
    color: $color-primary-highlight;
  }
}

.btn-comments {
  font-size: 1rem;
  background-color: transparent;
  color: $color-text-primary;
  font-weight: $regular;
  border-radius: 0;
  padding: 0;
  width: fit-content;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  transition: $main-transition;
  -webkit-transition: $main-transition;
          
  &:hover {
    color: $color-primary;
    transition: $main-transition;
    -webkit-transition: $main-transition;
  }
}

.btn-num-comments {
  min-width: max-content;
}

.btn-add-comment {
  display: flex;
  min-width: 6.15rem;
  margin-left: auto;
}

.btn-cancel-add-comment {
  font-family: $main-font;
  color: $color-text-primary;
  background-color: $color-accent;
  border: 1px solid $color-accent;
  padding: 0.7rem 2rem;

  &:hover,
  &:active {
    color: $color-text-primary;
    background-color: $color-accent;
  }
}

.btn-edit-post,
.btn-report-text,
.btn-delete-post {
  margin-left: 0.3rem;
}

.btn-report-post {
  text-align: right;
}

.btn-report-comment {
  color: $color-text-primary;
  background-color: transparent;
  font-weight: $regular;
  font-size: 1rem;
  cursor: pointer;
  text-align: left;
  padding: 0;
  margin-left: auto;
  border-radius: 0;

  &:hover {
    color: $color-primary;
  }
}

.btn-forum-post-see-more {
  color: $color-primary;
  white-space: nowrap;
  padding-left: 0.5rem;
  cursor: pointer;

  &:hover {
    color: $color-primary-highlight;
  }
}

.btn-forum-login {
  font-size: 1.12rem;
  font-weight: $bold;
  background-color: transparent;
  border-radius: 0;
  padding: 0;
  height: fit-content;

  &:hover {
    background-color: transparent;
    color: $color-primary;
  }
}

.btn-unsave-post {
  position: relative;
  margin-left: 0.3rem;
  z-index: 75;
}

.btn-see-all-posts {
  display: block;
  font-weight: $regular;
  font-size: 1rem;
  padding: 0.5rem 2rem;
  margin-top: 1.5rem;
}

.btn-onboarding-finish {
  display: block;
  color: $white;
  background-color: $color-primary;
  font-size: 1.12rem;
  width: 100%;
  height: 3.12rem;
  border-radius: 3px;
  text-align: center;
  margin-top: 2.5rem;

  &:hover {
    background-color: $color-primary-highlight;
  }

  .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.btn-edit-account-details {
  font-weight: $bold;
  font-size: 1.12rem;
  color: $color-text-primary;
  background-color: transparent;
  text-decoration: underline;
  z-index: 15;
  padding: 0;
}

.btn-account {
  width: 7.25rem;
  padding: 0.7rem 0;

  &:hover {
    box-shadow: $icon-shadow;
  }
}

.btn-account-cancel {
  color: $black;
  background-color: $white;
  border: 1px solid $black;

  &:hover {
    background-color: $color-secondary-5;
  }
}

.btn-account-save {
  color: $white;
  background-color: $color-primary;
  border: 1px solid $color-primary;

  &:hover {
    background: $color-primary-highlight;
  }
}

.btn-navbar {
  font-weight: $regular;
  font-size: 1.12rem;
  padding: 0.25rem 2.25rem;
}

.btn-return-community {
  display: inline-block;
  margin-top: 1.5rem;
}

.modal-x {
  position: absolute;
  font-size: 1.12rem;
  color: $black;
  top: 1.6rem;
  right: 1.6rem;
  transform: scaleX(1.15);
  transition: $main-transition;
  -webkit-transition: $main-transition;
  cursor: pointer;

  &:hover {
    color: $color-primary;
    transition: $main-transition;
    -webkit-transition: $main-transition;
  }
}

.btn-create-post-x {
  top: 1.5rem;
  right: 1.6rem;
}

.btn-guidelines-close {
  width: 100%;
  text-align: center;
  bottom: 0;
}

.btn-tooltip-close {
  position: absolute;
  color: $black;
  left: calc(100% - 1.65rem);
  top: 0.65rem;
  padding: 0.25rem;
  cursor: pointer;
}

.btn-submit-feature-voting {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 3rem;
  margin-top: 1.5rem;
}

.btn-submit-services-location {
  font-size: 1.12rem;
  font-weight: $regular;
  min-width: 10.5rem;
  width: 12rem;
  height: 3rem;
  padding: 0;
}

.btn-services-help {
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 1.12rem;
  bottom: 1.5rem;
  left: 100%;
  margin: 7rem 0 -7rem 0;
}

.btn-services-see-more {
  background-color: transparent;
  outline: none;
  padding: 0;
  margin-left: 0.25rem;
}

.btn-service-contact-details {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: $black;
  background-color: $color-neutral;
  border-radius: 0.25rem;
  padding: 0.125rem 0.5rem;

  &:hover,
  &:visited {
    color: $black;
    background-color: $color-neutral;
  }
}

.btn-services-call-now {
  padding: 0.5rem 2rem;
}

.btn-book-services {
  display: block;
}

.btn-book-service {
  display: flex;
  align-items: center;
  height: 2.7rem;
  width: fit-content;

  &:visited,
  &:active {
    background-color: $color-primary;
    
    &:hover {
      background-color: $color-primary-highlight;
      transition: $main-transition;
      -webkit-transition: $main-transition;
    }
  }
}

.btn-disabled {
  color: $white;
  background-color: $color-text-secondary !important;
  border-color: $color-text-secondary;
  cursor: default !important;

  &:hover {
    color: $white;
    background-color: $color-text-secondary !important;
    border-color: $color-text-secondary;
    box-shadow: none;
  }
}

.btn-onboarding-back {
  position: absolute;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  background-color: transparent;
  color: $color-primary;
  height: 2rem;
  padding: 0 0.3rem;
  margin-top: 0.15rem;
  margin-left: -0.3rem;

  &:hover {
    color: $color-primary-highlight;
  }
}

.btn-service-location {
  display: flex;
  gap: 1rem;
  align-items: center;
  font-size: 1.5rem;
  font-weight: $bold;
  min-height: 33px;
  color: $color-primary;
  background-color: transparent;
  border-bottom: 1px solid $black;
  border-radius: 0;
  line-height: 1;
  padding: 0 0.5rem;

  &:hover {
    color: $color-primary-highlight;
  }
  
  &:focus {
    color: $color-primary-highlight;
    background-color: $color-secondary-4;
  }
}

.btn-service-location-caret {
  color: $black;
}

.btn-calling-services {
  display: block;
  margin: 2rem auto 0 auto;
}

.btn-schedule-call {
  display: block;
  font-size: 1.25rem;
  width: fit-content;
  padding: 1.25rem 2.5rem;
}

.btn-schedule-call-mobile {
  display: none;
}

.btn-home-copilot {
  background-color: $color-secondary-2;
  border-color: $color-secondary-2;
  color: $black;
  padding: 0.65rem 2rem;

  &:hover {
    background-color: $color-secondary-1;
    border-color: $color-secondary-1;
  }
}

.btn-pricing {
  display: block;
  text-align: center;
  margin-top: 1rem;
}

.btn-author {
  background-color: $color-secondary-2;
  border-color: $color-secondary-2;
  color: $black;
  padding: 0.65rem 1.9rem;

  &:hover {
    background-color: $color-secondary-1;
    border-color: $color-secondary-1;
  }
}
