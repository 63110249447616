.account-container {
  display: flex;
  gap: 0.5rem 1.5rem;
  color: $color-text-primary;
  width: 100%;
  max-width: 75rem;
  margin: 1.75rem auto 3rem auto;
}

.account-completion-container {
  background-color: $white;
  width: 26rem;
  height: fit-content;
  border-radius: 0.63rem;
  box-shadow: $main-shadow;
  padding: 3rem;
}

.completion-donut-container {
  width: 11.5rem;
  height: 11.5rem;
  margin-top: -1rem;
}

.completion-donut-unfilled {
  stroke: $color-accent;
  stroke-width: 3;
  fill: transparent;
}

.completion-donut-filled {
  animation: show-percentage 1s reverse;
  stroke: $color-primary;
  stroke-width: 3;
  fill: transparent;
  stroke-dashoffset: 25;
}

.completion-percentage {
  font-family: $main-font;
  font-size: 0.5rem;
  font-weight: $medium;
  fill: $color-text-primary;
  text-anchor: middle;
}

.completion-section-hr {
  width: 17.5rem;
  border-bottom: 1px solid $color-accent;
  margin-top: 1.5rem;
}

.completion-section {
  margin-top: 1.5rem;
}

.completion-section-title {
  font-weight: $bold;
  line-height: 1.5rem;
}

.completion-cross {
  font-size: 1.12rem;
  color: $color-accent;
  background-color: $color-text-secondary;
  border-radius: 50%;
  margin-right: 0.38rem;
}

.completion-tick {
  color: $color-secondary-3;
  background-color: $color-primary;
  border-radius: 50%;
  margin-right: 0.38rem;
}

.completion-section-body {
  line-height: 1.55rem;
  margin: 0.5rem 0 0 0;
}

.completion-show-more {
  font-weight: $semibold;
  text-decoration: underline;
  margin-left: 0.25rem;
  cursor: pointer;
}

.completion-show-less {
  font-weight: $semibold;
  font-size: 1.12rem;
  width: 100%;
  text-decoration: underline;
  text-align: center;
  margin: 1.5rem 0;
  cursor: pointer;
}

.account-details-container {
  min-width: 16.5rem;
  width: 100%;
  min-height: 50rem;
  height: 100%;
  background-color: $white;
  border-radius: 0.63rem;
  box-shadow: $main-shadow;
}

.account-title {
  color: $color-text-primary; 
  border-bottom: 1px solid $color-accent;
  padding: 1.5rem 2rem;
}

.account-details-inner-container {
  position: relative;
  border-radius: 0.63rem;
  padding: 3rem 3rem 6rem 3rem;
}

.account-details-overlay {
  position: absolute;
  background-color: $white;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  border-radius: 0.63rem;
  z-index: 49;
  top: 0;
  left: 0;
}

.account-details-title-container {
  display: inline-block;
}

.account-profile-photo-saved-container {
  margin-left: 0.63rem;
}

.account-profile-photo-saved-container-mobile {
  display: none;
  text-align: right;
  margin-top: 0.25rem;
}

.account-profile-photo-spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-secondary-3;
  opacity: 0.75;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  top: 2.05rem;

  .spinner-border {
    color: $white;
  }
}

.account-profile-photo-saved {
  color: $color-primary;
}

.account-profile-image {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  object-fit: cover;
  margin-top: 0.5rem;
}

.account-profile-photo-btn-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0
}

.account-details-section {
  position: relative;
}

.account-details-section-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 3rem;
}

.account-details-row-container {
  display: flex;
  gap: 1rem;
  padding-top: 3rem;
}

.account-details-row-updating {
  position: relative;
  z-index: 50;
}

.account-details-section-subtitle {
  display: block;
  font-size: 1rem;
}

.account-details-label {
  display: block;
}

.account-details-input-container {
  position: relative;
  width: 100%;
}

.account-details-input {
  color: $color-text-primary;
  background-color: $white;
  width: 100%;
  height: 3.12rem;
  border: 1px solid $color-accent;
  border-radius: 3px;
  outline: none;
  padding-left: 1rem;
  margin-top: 0.5rem;

  &:focus-within {
    border-color: $color-primary;
    box-shadow: $input-focus-shadow;
  }
}

.account-section-title {
  padding-right: 0.6rem;
}

.account-section-saved {
  color: $color-primary;
}

.account-handle-success-tick {
  position: absolute;
  font-size: 1.2rem;
  background-color: $color-primary;
  color: $color-secondary-5;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  right: 0.75rem;
}

.account-input-not-unique {
  border-color: $color-danger-primary;
  box-shadow: $error-shadow;
  
  &:focus-within {
    border-color: $color-danger-primary;
    box-shadow: $error-shadow;
  }
}

.account-details-value {
  font-weight: $bold;
  margin-top: 0.5rem;
}

.account-details-no-value {
  color: $color-text-secondary;
  font-weight: $bold;
  margin-top: 0.5rem;
}

.account-details-btn-container {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
}

.account-update-location-form {
  .account-details-row-container {
    flex-wrap: wrap;
  }
}

.account-location-input-container {
  width: 100%;
}

.account-radio-container {
  display: block;
  width: 100%;
  margin-top: 1.5rem;
}

.account-details-radio-container {
  width: 100%;
}

.account-radio-input {
  position: absolute;
  opacity: 0;

  &:checked {
    + .account-radio-option {
      &::before {
        background-color: $color-primary;
        border-color: $color-primary;
        box-shadow: inset 0 0 0 0.2rem $white;
      }
    }

    + .account-checkbox-option {
      &::before {
        font-family: "Font Awesome 6 Free";
        content: "\f00c";
        font-weight: 900;
        font-size: 0.85rem;
        color: $white;
        background-color: $color-primary;
        border-color: $color-primary;
        line-height: 1.25;
      }
    }
  }
}

.account-radio-option,
.account-checkbox-option {
  display: flex;
  align-items: center;
  color: $color-text-primary;
  cursor: pointer;
  margin-left: 1rem;
  margin-bottom: 1rem;

  &:before {
    content: '';
    display: inline-block;
    position: relative;
    background-color: $white;
    width: 1.25rem;
    min-width: 1.25rem;
    height: 1.25rem;
    border: 2px solid $color-text-primary;
    border-radius: 100%;
    text-align: center;
    cursor: pointer;
    margin-right: 1rem;
  }
}

.account-checkbox-option {
  &:before {
    border-radius: 0;
  }
}

.account-radio-text-input {
  color: $color-text-primary;
  background-color: $white;
  flex: 1;
  min-width: 0;
  max-width: 20rem;
  outline: none;
  border: none;
  border-bottom: 1px solid $color-text-primary;
  margin-left: 0.5rem;
}

.account-tooltip-container {
  & .tooltip-inner {
    font-family: $main-font;
    font-size: $medium-text;
    color: $white;
    background-color: $black;
    min-width: 20rem;
    border-radius: 2px;
    text-align: left;
  }

  & .arrow::after {
    border-right-color: $black !important;
    border-left-color: $black !important;
  }
}

.account-tooltip {
  font-size: 1.12rem;
  color: $color-text-primary;
  cursor: pointer;
  pointer-events: all;
  margin-left: 0.25rem;
}
